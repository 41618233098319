import { ITrackingEvent } from '../../interfaces/tracking-event';

export const setEcommerceProcesses = (processes: ITrackingEvent[]) => ({
  type: 'SET_ECOMMERCE_PROCESSES',
  payload: processes,
});

export const setEcommerceActions = (actions: string[]) => ({
  type: 'SET_ECOMMERCE_ACTIONS',
  payload: actions,
});
