import styled from 'styled-components';
import colors from '../resources/colors';
import sizes from '../resources/sizes';

interface ILink {
  size?: string;
  lineHeight?: string;
  allCaps?: boolean;
}

const Link = styled.a`
  color: ${colors.royal};
  text-decoration-line: underline;
  cursor: pointer;
  font-size: ${(props: ILink) => (props.size ? props.size : sizes.descriptionFontSize)};
  line-height: ${(props: ILink) =>
    props.lineHeight ? props.lineHeight : sizes.descriptionLineHeight};
  text-transform: ${(props: ILink) => (props.allCaps ? 'uppercase' : 'none')};
`;

export default Link;
