export default class VtexOrderExtractor {
  static extractFromAccountOrder(url: string): string | undefined {
    return this.extractUsingRegex(url);
  }

  static extractFromOrderConfirmation(url: string): string | undefined {
    const regexResult = /og=([0-9a-z]+)/gi.exec(url);
    return regexResult && regexResult.length > 1 ? `${regexResult[1]}-01` : undefined;
  }

  static extractUsingRegex(text: string) {
    const regexResult = /([0-9a-z]+-[0-9a-z]{2})/gi.exec(text);
    return regexResult && regexResult.length > 1 ? regexResult[1] : undefined;
  }
}
