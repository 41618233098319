import { MountConfigType } from '../mount-config/config-types';

export const defaultClassesConfig: MountConfigType = {
  account_order: '',
  order_confirmation: '',
  public_order: '',
};

export const defaultStylesConfig: MountConfigType = {
  public_order: '',
  order_confirmation: 'margin-top: 10px;margin-bottom: 10px;',
  account_order: 'margin-top: 10px;margin-bottom: 10px;',
};

export const defaultMountSelectorConfig: MountConfigType = {
  public_order: '',
  order_confirmation:
    '#finalize-container > div.row.animated.ch-animated > div.col-md-7 > div > div > h2',
  account_order: 'div.board > div.TimeLine5',
};

export const defaultOrderExtractorConfig: MountConfigType = {
  public_order: '',
  order_confirmation: 'h2.order-id',
  account_order: 'main > div.board > p > span',
};

export const defaultConfirmationExtractorConfig: MountConfigType = {
  public_order: '',
  order_confirmation: '',
  account_order: '',
};
