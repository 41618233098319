import { CustomerState } from '../reducers/customer';

export const setCustomer = (payload: CustomerState) => ({
  type: 'SET_CUSTOMER',
  payload,
});

export const setCustomerEmail = (payload: string) => ({
  type: 'SET_CUSTOMER_EMAIL',
  payload,
});

export const setCustomerPhone = (payload: string) => ({
  type: 'SET_CUSTOMER_PHONE',
  payload,
});
