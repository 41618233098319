import styled from 'styled-components';
import sizes from '../../resources/sizes';

const ChannelSelectionLayout = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: ${sizes.maxWidthMobile}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export default ChannelSelectionLayout;
