import * as Sentry from '@sentry/browser';
import { MountStrategy } from '../mount-strategy';
import {
  shopifyCustomizeEntryPoint,
  shopifyDetermineLocation,
  shopifyExtractConfirmation,
  shopifyExtractOrder,
  shopifyGetTarget,
} from '../utils/shopify-utils';
import { TRAKR_WIDGET_ELEMENT_ID } from '../mounter-helpers';

const shopifyMountStrategy: MountStrategy = (onMount, onUnmount) =>
  function shopifyHandler() {
    try {
      const url = window.location.href;
      const hasLocation = shopifyDetermineLocation(url);
      const target = shopifyGetTarget(hasLocation.type);

      if (hasLocation.type && target && !document.querySelector(`#${TRAKR_WIDGET_ELEMENT_ID}`)) {
        const entryPoint = shopifyCustomizeEntryPoint(hasLocation.type);

        if (entryPoint) {
          target?.parentNode?.insertBefore(entryPoint, target.nextSibling);
          const order = shopifyExtractOrder(url, hasLocation.type);
          const confirmation = shopifyExtractConfirmation(url, hasLocation.type);

          if (typeof order === 'string') {
            onMount(entryPoint, order, confirmation ?? undefined);
          }
        }
      }

      if (!hasLocation.type) {
        onUnmount();
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

export default shopifyMountStrategy;
