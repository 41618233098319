import styled from 'styled-components';
import colors from '../resources/colors';

interface Props {
  error?: boolean;
}
const Textarea = styled.textarea`
  box-sizing: border-box;

  border-radius: 4px;
  padding: 8px 16px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  height: auto;
  background-color: ${colors.inputBackground};
  color: ${colors.inputColor};
  border: 2px solid ${(props: Props) => (props.error ? colors.red : colors.superLightGray)};

  &:focus {
    border: 2px solid ${colors.descriptionForeground};
  }
`;

export default Textarea;
