import styled from 'styled-components';
import sizes from '../resources/sizes';
import colors from '../resources/colors';

interface ITitle {
  fontSize?: string;
  lineHeight?: string;
  color?: string;
}

const Title = styled.h1`
  font-size: ${(props: ITitle) => (props.fontSize ? props.fontSize : sizes.titleFontSize)};
  line-height: ${(props: ITitle) => (props.lineHeight ? props.lineHeight : sizes.titleLineHeight)};
  color: ${(props: ITitle) => (props.color ? props.color : colors.titleForeground)};
  font-weight: bold;
  font-style: normal;
  text-align: center;

  @media screen and (max-width: ${sizes.maxWidthMobile}) {
    text-align: start;
  }
`;

export default Title;
