import styled from 'styled-components';

const HugeTitle = styled.h1`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
`;

export default HugeTitle;
