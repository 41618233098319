import { MountConfigType, MountLocation } from './config-types';

export default class OrderExtractorConfig {
  public readonly config: MountConfigType;

  constructor(config: MountConfigType) {
    this.config = config;
  }

  getSelector(location: MountLocation) {
    return (window as any).TRAKR_ORDER_SELECTORS_MAP
      && (window as any).TRAKR_ORDER_SELECTORS_MAP[location]
      ? (window as any).TRAKR_ORDER_SELECTORS_MAP[location]
      : this.config[location];
  }
}
