import React from 'react';
import { useTranslation } from 'react-i18next';
import ChannelOptionButton from '../../elements/channel-option-button';
import colors from '../../resources/colors';
import WhatsAppIcon from '../../resources/icons/whatsapp';

type Props = {
  onClick(): void;
};

export default function WhatsappOption(props: Props) {
  const { t } = useTranslation();

  return (
    <ChannelOptionButton
      label={t('WHATSAPP')}
      Icon={<WhatsAppIcon background={colors.buttonForeground} />}
      accent={colors.whatsapp}
      onClick={props.onClick}
    />
  );
}
