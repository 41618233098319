import { put, select } from 'redux-saga/effects';

import {
  setApplicationMode,
  setApplicationReady,
  getInitialData,
  setApplicationLoading,
  setApplicationEmail,
  setApplicationPoolingInterval,
} from '../actions/app';

import {
  setTrackingActions,
  setTrackingCode,
  setTrackingEstimatedDelivery,
  setTrackingEvents,
  startPooling,
  setTrackingUnknownCourier,
} from '../actions/tracking';

import { selectChannel, setChannelOptions, setStoredSelectedChannels } from '../actions/channel';

import { StateModel } from '../state-model';
import { fetchData } from './network';

import endpoints from '../../config/endpoints';
import * as mode from '../../config/constants/mode';

import { ITrackingEvent } from '../../interfaces/tracking-event';
import { setOrderStatus, setOrderTrackingUrl } from '../actions/order';
import { setEcommerceActions, setEcommerceProcesses } from '../actions/ecommerce';
import { setCustomer } from '../actions/customer';
import { SMS, WHATSAPP } from '../../config/constants/channel';

function isUnknownCourier(tracking: any) {
  return !!tracking.events.find((event: ITrackingEvent) => Number(event.code) === 15);
}

function setShowedChannelSelectionPage(): void {
  localStorage.setItem('showedChannelSelectionPage', JSON.stringify('true'));
}

export function* asyncBootstrapApp() {
  // get the initial data
  yield put(getInitialData());
}

export function* asyncGetInitialData() {
  yield put(setApplicationLoading(true));

  try {
    const order = yield select((state: StateModel) => state.app.order);
    const confirmation = yield select((state: StateModel) => state.app.confirmation);
    const xhost = yield select((state: StateModel) => state.app.xhost);
    const bypassCheck = yield select((state: StateModel) => state.channel.bypassCheck);
    const server = yield select((state: StateModel) => state.app.server);
    const showChannelSelectionPage = localStorage.getItem('showedChannelSelectionPage');

    if (!showChannelSelectionPage) setShowedChannelSelectionPage();

    if (typeof order === 'string') {
      const response = yield fetchData({
        url: `${server + endpoints.tracking}?order=${order}${
          confirmation ? '&confirmation=' + confirmation : ''
        }`,
        method: 'GET',
        xhost,
      });

      if (response && !(response instanceof Error)) {
        const {
          trackings,
          channels,
          channelList,
          deliveredAt,
          customer,
          status,
          demo,
          fastUpdate,
          ecommerceProcess,
        } = response;

        if (demo === true || fastUpdate === true) {
          yield put(setApplicationPoolingInterval(10000));
        }

        // TODO: implement way when you have more than one tracking
        if (typeof trackings === 'object') {
          yield put(setApplicationReady(true));

          if (!!showChannelSelectionPage || bypassCheck === true) {
            yield put(setApplicationMode(mode.TRACKING));
          } else {
            yield put(setApplicationMode(mode.CHOOSE_CHANNEL));
          }

          yield put(setChannelOptions(channelList));
          yield put(setApplicationEmail(customer.email));
          yield put(setCustomer(customer));
          yield put(setOrderStatus(status));
          yield put(setEcommerceProcesses(ecommerceProcess?.events ?? []));
          yield put(setEcommerceActions(ecommerceProcess?.actions ?? []));
          yield put(setStoredSelectedChannels(channels));

          if (customer?.phone) {
            const smsChannel = channelList.find((chan: any) => chan.id === SMS);
            const whatsappChannel = channelList.find((chan: any) => chan.id === WHATSAPP);

            if (smsChannel && !whatsappChannel) {
              yield put(selectChannel(smsChannel));
            }
          }

          if (trackings.length) {
            const tracking = trackings[0];

            if (tracking.events.length === 1) {
              const unknownCourier = isUnknownCourier(tracking);

              if (unknownCourier) {
                yield put(setApplicationMode(mode.TRACKING));
                yield put(setTrackingUnknownCourier(true));
              }
            }

            yield put(setTrackingCode(tracking.code));
            yield put(setTrackingEstimatedDelivery(tracking.estimatedDeliveryDate));
            yield put(setTrackingEvents(tracking.events.filter((event: any) => !!event)));
            yield put(setTrackingActions(tracking.actions));
            yield put(setOrderTrackingUrl(tracking.trackingUrl));
          } else {
            yield put(setTrackingEstimatedDelivery(deliveredAt));
          }

          yield put(startPooling());
        }
      } else {
        yield put({ type: 'ERROR', payload: response.message });
      }
    }
  } catch (error) {
    yield put({ type: 'ERROR', error });
  } finally {
    yield put(setApplicationLoading(false));
  }
}
