import { ITrackingEvent } from '../../interfaces/tracking-event';
import { IReduxAction } from '../../interfaces/redux-action';

export interface EcommerceState {
  processes: ITrackingEvent[];
  actions: string[];
}

const initialState: EcommerceState = {
  actions: [],
  processes: [],
};

export default function ecommerce(
  state: EcommerceState = initialState,
  action: IReduxAction,
): EcommerceState {
  switch (action.type) {
    case 'SET_ECOMMERCE_PROCESSES':
      return { ...state, processes: action.payload };
    case 'SET_ECOMMERCE_ACTIONS':
      return { ...state, actions: action.payload };
    default:
      return state;
  }
}
