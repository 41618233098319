import React from 'react';
import { connect } from 'react-redux';

import { StateModel } from '../store/state-model';
import ChannelSelector from '../components/channel-options/channel-selector';

import ChannelConfirmationMessage from '../components/channel-options/channel-confirmation-message';
import PageContainer from '../elements/page-container';
import AbsoluteRight from '../elements/position/absolute-right';
import LogoContainer from '../elements/logo-container';
import Container from '../elements/container';

import ContainerVertical from '../elements/container-vertical';

import { IChannel } from '../interfaces/channel';
import ChannelSelectionLayout from '../elements/layout/channel-selection-layout';
import PoweredByIcon from '../resources/icons/powered-by';
import TrackrLogoIcon from '../resources/icons/trackr-logo';

interface IChannelSelectionPage {
  selected?: IChannel;
}

export function ChannelSelectionPage(props: IChannelSelectionPage) {
  return (
    <ContainerVertical custom>
      <PageContainer>
        <ChannelSelectionLayout>
          <ChannelConfirmationMessage selected={props.selected} />
          <ChannelSelector />
        </ChannelSelectionLayout>
      </PageContainer>

      <Container fullWidth height="26px" custom>
        <AbsoluteRight>
          <LogoContainer>
            <PoweredByIcon color="#9B9B9B" />
            <TrackrLogoIcon />
          </LogoContainer>
        </AbsoluteRight>
      </Container>
    </ContainerVertical>
  );
}

const mapState = (state: StateModel) => ({
  selected: state.channel.selected,
});

export default connect(mapState)(ChannelSelectionPage);
