export const TRAKR_WIDGET_ELEMENT_ID = 'send4-trakr';

export function debounce(ms, fn) {
  let timer;

  return () => {
    clearTimeout(timer);
    timer = setTimeout(fn, ms);
  };
}
