import * as Sentry from '@sentry/browser';
import { MountStrategy } from '../mount-strategy';
import * as trayCommerceUtils from '../utils/tray-commerce-utils';
import { TRAKR_WIDGET_ELEMENT_ID } from '../mounter-helpers';

const trayCommerceMountStrategy: MountStrategy = (onMount, onUnmount) =>
  function trayCommerceHandler() {
    try {
      const url = window.location.href;
      const location = trayCommerceUtils.determineLocation(url);
      const target = location ? trayCommerceUtils.getTarget(location) : undefined;

      if (location && target && !document.querySelector(`#${TRAKR_WIDGET_ELEMENT_ID}`)) {
        const entrypoint = trayCommerceUtils.createEntrypoint(location);

        if (entrypoint) {
          target?.parentNode?.insertBefore(entrypoint, target.nextSibling);
          const order = trayCommerceUtils.extractOrder(location);
          const confirmation = trayCommerceUtils.extractConfirmation(location);

          if (typeof order === 'string') {
            onMount(entrypoint, order, confirmation ?? undefined);
          }
        }
      }

      if (!location) {
        onUnmount();
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

export default trayCommerceMountStrategy;
