import React from 'react';
import { useTranslation } from 'react-i18next';
import { IChannel } from '../../interfaces/channel';
import EventItem, { IEventItem } from './event-item';
import * as channels from '../../config/constants/channel';
import * as TrackingActions from '../../config/constants/tracking-actions';
import PhoneMask from '../../masks/phone';

interface Props extends IEventItem {
  channel: IChannel;
}

const selectDetails = (channel: IChannel, t: (key: string, config?: any) => string) => {
  switch (channel.channel) {
    case channels.SMS:
      return t('EVENT_CHANNEL_SMS_SELECTED', { phone: PhoneMask.mask(channel.data.phone) });
    case channels.MESSENGER:
      return t('EVENT_CHANNEL_MESSENGER_SELECTED');
    default:
      return '';
  }
};

export default function ChannelItem(props: Props) {
  const { t } = useTranslation();

  const description = props.channel.enabled === false ? t('NO_NOTIFICATIONS') : t('EVENT_CHANNEL_SELECTED');
  const details = props.channel.enabled === false
    ? t('EVENT_NO_CHANNEL_SELECTED')
    : selectDetails(props.channel, t);

  return (
    <EventItem
      actions={[
        props.channel.channel ? TrackingActions.CHANGE_CHANNEL : TrackingActions.SELECT_CHANNEL,
      ]}
      date={0}
      code=""
      description={description}
      details={details}
      disableConnector={props.disableConnector}
      disableBottomSpacer={props.disableBottomSpacer}
      isFirst={props.isFirst}
      isLast={props.isLast}
    />
  );
}
