import { useChannelEvents } from "./use-channel-events";
import { useEcommerceProcesses } from "./use-ecommerce-processes";
import { useTrackingEvents } from "./use-tracking-events";

export function useTimelineLength() {
  const trackingEvents = useTrackingEvents()
  const processEvents = useEcommerceProcesses()
  const channelEvents = useChannelEvents()

  return trackingEvents.length + processEvents.length + channelEvents.length
}