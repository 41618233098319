import { ITrackingEvent } from '../../interfaces/tracking-event';
import { IReduxAction } from '../../interfaces/redux-action';

export interface TrackingState {
  actions: string[];
  events: ITrackingEvent[];
  trackingCode?: string;
  estimatedDelivery?: number;
  trackingExpanded?: boolean;
  unknownCourier?: boolean;
}

const initialState: TrackingState = {
  events: [],
  trackingCode: undefined,
  trackingExpanded: false,
  estimatedDelivery: undefined,
  actions: [],
  unknownCourier: false,
};

export default function tracking(
  state: TrackingState = initialState,
  action: IReduxAction,
): TrackingState {
  switch (action.type) {
    case 'SET_TRACKING_EVENTS':
      return { ...state, events: action.payload };
    case 'SET_TRACKING_ACTIONS':
      return { ...state, actions: action.payload };
    case 'SET_TRACKING_CODE':
      return { ...state, trackingCode: action.payload };
    case 'SET_TRACKING_LIST_EXPANDED':
      return { ...state, trackingExpanded: action.payload };
    case 'SET_TRACKING_ESTIMATED_DELIVERY':
      return { ...state, estimatedDelivery: action.payload };
    case 'SET_TRACKING_UNKNOWN_COURIER':
      return { ...state, unknownCourier: action.payload };
    default:
      return state;
  }
}
