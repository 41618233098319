import { IReduxAction } from '../../interfaces/redux-action';
import { ITrackingEvent } from '../../interfaces/tracking-event';

export const setTrackingEvents = (events: ITrackingEvent[]): IReduxAction => ({
  type: 'SET_TRACKING_EVENTS',
  payload: events,
});

export const setTrackingActions = (actions: string[]): IReduxAction => ({
  type: 'SET_TRACKING_ACTIONS',
  payload: actions,
});

export const setTrackingCode = (trackingCode: string): IReduxAction => ({
  type: 'SET_TRACKING_CODE',
  payload: trackingCode,
});

export const setTrackingUnknownCourier = (unknownCourier: boolean): IReduxAction => ({
  type: 'SET_TRACKING_UNKNOWN_COURIER',
  payload: unknownCourier,
});

export const setTrackingEstimatedDelivery = (estimatedDelivery: number): IReduxAction => ({
  type: 'SET_TRACKING_ESTIMATED_DELIVERY',
  payload: estimatedDelivery,
});

export const doAction = (action: { name: string; informedData?: string }): IReduxAction => ({
  type: 'ASYNC_DO_TRACKING_ACTION',
  payload: action,
});

export const setTrackingListExpanded = (expanded: boolean): IReduxAction => ({
  type: 'SET_TRACKING_LIST_EXPANDED',
  payload: expanded,
});

export const getTrackingEvents = (enableLoading = true): IReduxAction => ({
  type: 'ASYNC_GET_TRACKING_EVENTS',
  payload: enableLoading,
});

export const startPooling = (): IReduxAction => ({
  type: 'ASYNC_START_TRACKING_POOLING',
});
