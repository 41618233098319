import React from 'react';
import IconContainerRounded from './icon-container-rounded';
import Button from './button';
import colors from '../resources/colors';
import HSpacer from './spacer-horizontal';

type Props = {
  label: string;
  Icon: any;
  accent: string;
  onClick(): void;
};

const ChannelOptionButton = ({ label, Icon, accent, onClick }: Props) => (
  <>
    <Button
      onClick={onClick}
      padding="8px"
      background={colors.buttonForeground}
      color={colors.textForeground}
      boxShadow="0 4px 8px rgba(230, 232, 241, 0.5)"
      justify="flex-start"
      disableAllCaps
    >
      <IconContainerRounded accent={accent}>{Icon}</IconContainerRounded>
      {label}
    </Button>
    <HSpacer space="20px" />
  </>
);

export default ChannelOptionButton;
