import React from 'react';
import { useTranslation } from 'react-i18next';
import ContainerVertical from '../../elements/container-vertical';
import Text from '../../elements/text';
import HSpacer from '../../elements/spacer-horizontal';
import { IChannel } from '../../interfaces/channel';

import * as channel from '../../config/constants/channel';
import HugeTitle from '../../elements/huge-title';

interface IChannelConfirmationMessage {
  selected?: IChannel;
}

export default function ChannelConfirmationMessage(props: IChannelConfirmationMessage) {
  const { t } = useTranslation();

  return (
    <ContainerVertical padding="5px" justify="flex-start" align="flex-start">
      <HugeTitle>{t('DELIVERY_NOTIFICATIONS')}</HugeTitle>

      <HSpacer space="20px" />

      {!props.selected && <Text>{t('WE_KEEP_YOU_UPDATED')}</Text>}
      {props.selected && props.selected.id === channel.WHATSAPP && (
        <Text>{t('WHATSAPP_CONNECT_CONFIRMATION')}</Text>
      )}
      {props.selected && props.selected.id === channel.MESSENGER && (
        <Text>{t('FB_MESSENGER_CONNECT_CONFIRMATION')}</Text>
      )}
      {props.selected && props.selected.id === channel.SMS && (
        <Text>{t('SMS_CONNECT_CONFIRMATION')}</Text>
      )}
    </ContainerVertical>
  );
}
