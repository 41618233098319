import { MountConfigType } from '../mount-config/config-types';

export const defaultClassesConfig: MountConfigType = {
  account_order: '',
  order_confirmation: '',
  public_order: '',
};

export const defaultStylesConfig: MountConfigType = {
  public_order: '',
  order_confirmation: '',
  account_order: '',
};

export const defaultMountSelectorConfig: MountConfigType = {
  public_order: '',
  order_confirmation: '',
  account_order: '',
};

export const defaultOrderExtractorConfig: MountConfigType = {
  public_order: '',
  order_confirmation: '',
  account_order: '',
};

export const defaultConfirmationExtractorConfig: MountConfigType = {
  public_order: '',
  order_confirmation: '',
  account_order: '',
};
