import axios from 'axios';
import { INJECT_ECOMMERCE } from '../../config/constants/tracking';

interface ISagaFetch {
  url: string;
  method: 'GET' | 'POST';
  payload?: object;
  xhost?: string;
}

// eslint-disable-next-line import/prefer-default-export
export async function fetchData(props: ISagaFetch): Promise<object | Error> {
  const host = INJECT_ECOMMERCE ?? props.xhost ?? window.location.host; // eslint-disable-line

  const options = {
    url: props.url,
    method: props.method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'x-host': host,
    },
    data: props.payload,
  };

  try {
    const response = await axios(options);
    if (/2../.test(String(response.status))) {
      try {
        return response.data;
      } catch {
        return {};
      }
    }

    return new Error(`Response is not ok: ${JSON.stringify(response)}`);
  } catch (error) {
    if (error.response.status === 403 && error.response.data.deactivated) {
      return new Error(`Ecommerce deactivated: ${JSON.stringify(error.response.data)}`);
    }

    return new Error(`Failed to fetch: ${JSON.stringify(props)}`);
  }
}
