import { IReduxAction } from '../../interfaces/redux-action';
import { IChannel } from '../../interfaces/channel';

export const bypassChannelChecking = (bypass: boolean): IReduxAction => ({
  type: 'SET_CHANNEL_BYPASS_CHECKING',
  payload: bypass,
});

export const selectChannel = (channel?: IChannel): IReduxAction => ({
  type: 'SET_SELECTED_CHANNEL',
  payload: channel,
});

export const setHasChannel = (hasChannel?: boolean): IReduxAction => ({
  type: 'SET_HAS_CHANNEL',
  payload: hasChannel,
});

export const asyncSelectChannel = (channel: string): IReduxAction => ({
  type: 'ASYNC_SET_SELECT_CHANNEL',
  payload: channel,
});

export const confirmChannelSelection = (payload: { channel: string; data: any }): IReduxAction => ({
  type: 'ASYNC_CONFIRM_CHANNEL_SELECTION',
  payload,
});

export const setChannelConfirmation = (confirm: boolean): IReduxAction => ({
  type: 'SET_SELECTED_CHANNEL_CONFIRMATION',
  payload: confirm,
});

export const setChannelOptions = (options: object[]): IReduxAction => ({
  type: 'SET_CHANNEL_OPTIONS',
  payload: options,
});

export const setStoredSelectedChannels = (channels: object[]): IReduxAction => ({
  type: 'SET_STORED_SELECTED_CHANNELS',
  payload: channels,
});
