import { IReduxAction } from '../../interfaces/redux-action';
import { IChannel } from '../../interfaces/channel';

export interface ChannelState {
  selected?: IChannel;
  hasChannel: boolean;
  selectedConfirmed: boolean;
  options: IChannel[];
  storedChannels: IChannel[];
  bypassCheck: boolean;
}

const initialState: ChannelState = {
  selected: undefined,
  hasChannel: false,
  selectedConfirmed: false,
  options: [],
  storedChannels: [],
  bypassCheck: false,
};

export default function channel(
  state: ChannelState = initialState,
  action: IReduxAction
): ChannelState {
  switch (action.type) {
    case 'SET_CHANNEL_BYPASS_CHECKING':
      return { ...state, bypassCheck: action.payload };
    case 'SET_SELECTED_CHANNEL':
      return { ...state, selected: action.payload };
    case 'SET_HAS_CHANNEL':
      return { ...state, hasChannel: action.payload };
    case 'SET_SELECTED_CHANNEL_CONFIRMATION':
      return { ...state, selectedConfirmed: action.payload };
    case 'SET_CHANNEL_OPTIONS':
      return { ...state, options: action.payload };
    case 'SET_STORED_SELECTED_CHANNELS':
      return { ...state, storedChannels: action.payload };
    default:
      return state;
  }
}
