import React from 'react';

import { useTranslation } from 'react-i18next';
import endpoints from '../config/endpoints';

import Text from '../elements/text';
import Link from '../elements/link';
import colors from '../resources/colors';

export const PolicyTerms = () => {
  const { t } = useTranslation();

  return (
    <Text color={colors.textForeground} size="10px">
      {t('POLICY_TERMS_MESSAGE')}
      &nbsp;
      <Link size="10px" href={endpoints.policy} rel="noopener noreferrer" target="_blank">
        {t('USE_TERMS_LABEL')}
      </Link>
      &nbsp;
      {t('AND')}
      &nbsp;
      <Link size="10px" href={endpoints.policy} rel="noopener noreferrer" target="_blank">
        {t('POLICY_TERMS_LABEL')}
      </Link>
    </Text>
  );
};

export default PolicyTerms;
