import React from 'react';
import ReactDOM from 'react-dom';
import AppMounter from './AppMounter';

export default function autoInit() {
  window.addEventListener('load', () => {
    const mounterTarget = document.createElement('div');
    document.body.appendChild(mounterTarget);

    // Mount the mounter helper, it will handle the mounting and unmount
    // of the entire application.
    ReactDOM.render(<AppMounter />, mounterTarget);
  });
}
