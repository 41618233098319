import { useChannelEvents } from './use-channel-events';
import { useEcommerceProcesses } from './use-ecommerce-processes';
import { useTrackingEvents } from './use-tracking-events';

export function useTimeline(): any[] {
  const trackingEvents = useTrackingEvents();
  const processEvents = useEcommerceProcesses();
  const channelEvents = useChannelEvents();

  return [...trackingEvents, ...processEvents, ...channelEvents];
}

