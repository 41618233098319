import { ConfirmationExtractor, OrderExtrator } from '../mount-strategy';
import * as view from '../../config/view';
import { TRAKR_WIDGET_ELEMENT_ID } from '../mounter-helpers';
import ShopifyOrderExtrator from '../order-extractor/shopify-order-extractor';
import EntrypointConfig from '../mount-config/entrypoint-config';
import MountSelectorConfig from '../mount-config/mount-selector-config';
import OrderExtractorConfig from '../mount-config/order-extractor-config';
import {
  defaultClassesConfig,
  defaultConfirmationExtractorConfig,
  defaultMountSelectorConfig,
  defaultOrderExtractorConfig,
  defaultStylesConfig,
} from '../defaults/shopify-defaults';
import { MountLocation } from '../mount-config/config-types';
import { DefaultConfirmationExtractor } from '../confirmation-extractor/default-confirmation-extractor';
import ConfirmationExtractorConfig from '../mount-config/confirmation-extractor-config';

const mountLocations = {
  order: /[0-9]+\/orders\/([a-f0-9]+)$/,
  accountOrder: /account\/orders\/([a-f0-9]+)$/,
  checkout: /[0-9]+\/checkouts\/([a-f0-9]+)\/thank_you$/,
};

const entrypointConfig = new EntrypointConfig(defaultClassesConfig, defaultStylesConfig);
const mountSelectorConfig = new MountSelectorConfig(defaultMountSelectorConfig);
const orderExtractorConfig = new OrderExtractorConfig(defaultOrderExtractorConfig);
const confirmationExtractorConfig = new ConfirmationExtractorConfig(
  defaultConfirmationExtractorConfig,
);

export const shopifyExtractOrder: OrderExtrator = (url, location) => {
  switch (location) {
    case view.CHECKOUT:
      return ShopifyOrderExtrator.extractFromCheckout(
        document,
        orderExtractorConfig.getSelector(view.CHECKOUT),
      );
    case view.ORDER:
      return ShopifyOrderExtrator.extractFromPublicOrder(
        document,
        orderExtractorConfig.getSelector(view.ORDER),
      );
    case view.ACCOUNT_ORDER:
      return ShopifyOrderExtrator.extractFromOrder(
        document,
        orderExtractorConfig.getSelector(view.ACCOUNT_ORDER),
      );
    default:
      return null;
  }
};

export const shopifyExtractConfirmation: ConfirmationExtractor = (url, location) => {
  switch (location) {
    case view.CHECKOUT:
      return DefaultConfirmationExtractor.extractFromQuery(
        document,
        confirmationExtractorConfig.getSelector(view.CHECKOUT),
      );
    case view.ORDER:
      return DefaultConfirmationExtractor.extractFromQuery(
        document,
        confirmationExtractorConfig.getSelector(view.ORDER),
      );
    case view.ACCOUNT_ORDER:
      return DefaultConfirmationExtractor.extractFromQuery(
        document,
        confirmationExtractorConfig.getSelector(view.ACCOUNT_ORDER),
      );
    default:
      return null;
  }
};

export function shopifyGetTarget(type: string | boolean) {
  return document.querySelector(mountSelectorConfig.getSelector(type as MountLocation));
}

export function shopifyCustomizeEntryPoint(type: MountLocation) {
  const entrypoint = document.createElement('div');
  entrypoint.setAttribute('id', TRAKR_WIDGET_ELEMENT_ID);
  // @ts-ignore
  entrypoint.style = entrypointConfig.getStyles(type) ?? '';
  const customClasses = entrypointConfig.getClasses(type);
  if (customClasses) {
    entrypoint.classList.add(customClasses);
  }
  return entrypoint;
}

export function shopifyDetermineLocation(url: string) {
  // reset the regex pointers
  mountLocations.accountOrder.lastIndex = 0;
  mountLocations.checkout.lastIndex = 0;
  mountLocations.order.lastIndex = 0;

  const order = mountLocations.order.test(url) && view.ORDER;
  const accountOrder = mountLocations.accountOrder.test(url) && view.ACCOUNT_ORDER;
  const checkout = mountLocations.checkout.test(url) && view.CHECKOUT;

  return { type: order || accountOrder || checkout };
}
