import React from 'react';
import { useTranslation } from 'react-i18next';
import ContainerVertical from '../../elements/container-vertical';
import EventItem from './event-item';

import * as eventCode from '../../config/constants/event-code';
import ShowMoreEvents from './show-more';
import ChannelItem from './channel-item';
import { IChannel } from '../../interfaces/channel';
import { useTimeline } from '../../hooks/use-timeline';
import { useTimelineLength } from '../../hooks/use-timeline-length';
import { useTimelineActions } from '../../hooks/use-timeline-actions';
import { useTimelineExpanded } from '../../hooks/use-timeline-expanded';

export default function EventList() {
  const events = useTimeline();
  const numberEvents = useTimelineLength();
  const actions = useTimelineActions();
  const [expanded] = useTimelineExpanded();

  const { t } = useTranslation();

  const isFirst = (index: number): boolean => {
    if (expanded) return index === numberEvents - 1;
    if (!expanded) return index === 1;
    return false;
  };

  return (
    <ContainerVertical align="flex-start">
      {numberEvents === 0 && (
        <>
          <EventItem
            key="waiting-event"
            actions={[]}
            code={String(eventCode.INIT)}
            isFirst
            isLast
            date={0}
            description={t('WAITING_NEW_EVENT')}
            details={t('WAITING_NEW_EVENT_MESSAGE')}
            disableBottomSpacer
            disableConnector
          />
        </>
      )}

      {numberEvents === 1 &&
        events.map((event) => {
          if (Number(event.code) === eventCode.COURIER_UNKNOWN) {
            return <></>;
          }

          return (
            <EventItem
              key="single-event"
              actions={[]}
              code={String(eventCode.INIT)}
              isLast
              date={0}
              description={t('WAITING_NEW_EVENT')}
              details={t('WAITING_NEW_EVENT_MESSAGE')}
            />
          );
        })}

      {numberEvents === 1 && (
        <EventItem
          isFirst
          actions={actions}
          code={events[0].code}
          date={events[0].date}
          description={events[0].description}
          details={events[0].details}
          key={events[0].description}
          disableConnector
          disableBottomSpacer
        />
      )}

      {numberEvents > 1 &&
        events
          .filter((_, index) => (expanded ? true : index < 2))
          .map((event, index) =>
            (event as any).channel !== undefined ? (
              <ChannelItem
                channel={(event as unknown) as IChannel}
                actions={[]}
                isLast={index === 0}
                isFirst={isFirst(index)}
                key={`channel-event-${index}`}
                disableConnector={numberEvents === 2 && index === 1}
                disableBottomSpacer={numberEvents === 2 && index === 1}
              />
            ) : (
              <EventItem
                actions={index === 0 ? actions : []}
                isLast={index === 0}
                isFirst={isFirst(index)}
                code={event.code}
                date={event.date}
                description={event.description}
                details={event.details}
                key={event.description}
                disableConnector={numberEvents === 2 && index === 1}
                disableBottomSpacer={numberEvents === 2 && index === 1}
              />
            )
          )}

      <ShowMoreEvents />
    </ContainerVertical>
  );
}
