import { all, debounce, takeEvery } from 'redux-saga/effects';
import * as app from './app';
import * as channel from './channel';
import * as tracking from './tracking';
import bubbleErrors from './error';

export default function* root() {
  yield all([
    debounce(250, 'ASYNC_BOOTSTRAP_APP', app.asyncBootstrapApp),
    debounce(250, 'ASYNC_GET_INITIAL_DATA', app.asyncGetInitialData),

    debounce(250, 'ASYNC_CONFIRM_CHANNEL_SELECTION', channel.asyncSelectChannel),

    debounce(250, 'ASYNC_DO_TRACKING_ACTION', tracking.asyncDoTrackingAction),
    debounce(300, 'ASYNC_GET_TRACKING_EVENTS', tracking.asyncGetTrackingEvents),
    debounce(300, 'ASYNC_START_TRACKING_POOLING', tracking.startTrackingPooling),

    takeEvery('ERROR', bubbleErrors),
  ]);
}

