import React from 'react';
import ReactDOM from 'react-dom';
import Tracking from '@send4br/tracking-module';
import { MISTER_S_URL } from './config/endpoints';

type TrackingConfig = {
  root: HTMLElement;
  order: string;
  confirmation: string;
};

type TrackingInit = {
  unmount(): void;
};

export const createTracking = () => ({
  init: (config: TrackingConfig): TrackingInit => {
    if (!config.order) {
      throw new TypeError(`The order is needed. Passed ${config.order}`);
    }

    if (!config.confirmation) {
      throw new TypeError(`The confirmation is needed. Passed ${config.confirmation}`);
    }

    if (!config.root) {
      throw new TypeError(`The root element is needed. Passed ${config.root}`);
    }

    ReactDOM.render(
      <Tracking order={config.order} confirmation={config.confirmation} server={MISTER_S_URL} />,
      config.root
    );

    return {
      unmount: () => {
        ReactDOM.unmountComponentAtNode(config.root);
      },
    };
  },
});

export default function manualInit() {
  const aftersale = {
    tracking: createTracking(),
  };

  // @ts-ignore
  window.aftersale = aftersale;

  return aftersale;
}
