import styled from 'styled-components';

const StepperSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  @media screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

export default StepperSection;
