import { MountConfigType } from '../mount-config/config-types';

export const defaultClassesConfig: MountConfigType = {
  public_order: '',
  order_confirmation: 'w-100 pt4',
  account_order: 'w-100 pt6 fl',
};

export const defaultStylesConfig: MountConfigType = {
  public_order: '',
  order_confirmation: '',
  account_order: '',
};

export const defaultMountSelectorConfig: MountConfigType = {
  public_order: '',
  order_confirmation: '#app-container div.dib.cf.w-100',
  account_order: 'main.vtex-account__page-body div.myo-progress-bar',
};

export const defaultOrderExtractorConfig: MountConfigType = {
  public_order: '',
  order_confirmation: '',
  account_order: '',
};

export const defaultConfirmationExtractorConfig: MountConfigType = {
  public_order: '',
  order_confirmation: '',
  account_order: '',
};
