import EntrypointConfig from '../mount-config/entrypoint-config';
import MountSelectorConfig from '../mount-config/mount-selector-config';
import OrderExtractorConfig from '../mount-config/order-extractor-config';
import { MountLocation } from '../mount-config/config-types';
import {
  defaultClassesConfig,
  defaultConfirmationExtractorConfig,
  defaultMountSelectorConfig,
  defaultOrderExtractorConfig,
  defaultStylesConfig,
} from '../defaults/tray-commerce-defaults';
import * as views from '../../config/view';
import { TRAKR_WIDGET_ELEMENT_ID } from '../mounter-helpers';
import TrayCommerceOrderExtractor from '../order-extractor/tray-commerce-order-extractor';
import { DefaultConfirmationExtractor } from '../confirmation-extractor/default-confirmation-extractor';
import ConfirmationExtractorConfig from '../mount-config/confirmation-extractor-config';

const mountLocations = {
  order: /^$/,
  accountOrder: /loja\/central_detalhe_pedido\.php\?loja=[0-9]+&id_pedido=([\d]+)/gi,
  checkout: /checkout\?session_id=([0-9a-z]+)&store_id=([0-9]+).+#finalizacao$/gi,
};

const entrypointConfig = new EntrypointConfig(defaultClassesConfig, defaultStylesConfig);
const mountSelectorConfig = new MountSelectorConfig(defaultMountSelectorConfig);
const orderExtractorConfig = new OrderExtractorConfig(defaultOrderExtractorConfig);
const confirmationExtractorConfig = new ConfirmationExtractorConfig(
  defaultConfirmationExtractorConfig,
);

export function createEntrypoint(location: MountLocation) {
  const entrypoint = document.createElement('div');
  entrypoint.setAttribute('id', TRAKR_WIDGET_ELEMENT_ID);
  // @ts-ignore
  entrypoint.style = entrypointConfig.getStyles(location) ?? '';
  const customClasses = entrypointConfig.getClasses(location);
  if (customClasses) entrypoint.classList.add(customClasses);
  return entrypoint;
}

export function determineLocation(url: string): MountLocation | undefined {
  // reset the regex pointers
  mountLocations.accountOrder.lastIndex = 0;
  mountLocations.checkout.lastIndex = 0;
  mountLocations.order.lastIndex = 0;

  const order = mountLocations.order.test(url) ? views.ORDER : undefined;
  const accountOrder = mountLocations.accountOrder.test(url) ? views.ACCOUNT_ORDER : undefined;
  const checkout = mountLocations.checkout.test(url) ? views.CHECKOUT : undefined;

  return order ?? accountOrder ?? checkout ?? undefined;
}

export function getTarget(location: MountLocation) {
  return document.querySelector(mountSelectorConfig.getSelector(location));
}

export function extractOrder(location: MountLocation) {
  switch (location) {
    case views.CHECKOUT:
      return TrayCommerceOrderExtractor.extractFromCheckout(
        document,
        orderExtractorConfig.getSelector(views.CHECKOUT),
      );
    case views.ACCOUNT_ORDER:
      return TrayCommerceOrderExtractor.extractFromAccountOrder(
        document,
        orderExtractorConfig.getSelector(views.ACCOUNT_ORDER),
      );
    default:
      return null;
  }
}

export function extractConfirmation(location: MountLocation) {
  switch (location) {
    case views.CHECKOUT:
      return DefaultConfirmationExtractor.extractFromQuery(
        document,
        confirmationExtractorConfig.getSelector(views.CHECKOUT),
      );
    case views.ACCOUNT_ORDER:
      return DefaultConfirmationExtractor.extractFromQuery(
        document,
        confirmationExtractorConfig.getSelector(views.ACCOUNT_ORDER),
      );
    default:
      return null;
  }
}
