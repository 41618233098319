import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import colors from '../../resources/colors';
import LeftIcon from '../../resources/icons/left';
import { IReduxAction } from '../../interfaces/redux-action';
import { confirmChannelSelection, selectChannel } from '../../store/actions/channel';
import { StateModel } from '../../store/state-model';

import ContainerVertical from '../../elements/container-vertical';
import ContainerHorizontal from '../../elements/container-horizontal';
import ResponsiveHSpacer from '../../elements/responsive-horizontal-spacer';
import ButtonTransparent from '../../elements/button-transparent';
import Button from '../../elements/button';
import WhatsAppIcon from '../../resources/icons/whatsapp';
import HSpacer from '../../elements/spacer-horizontal';
import VSpacer from '../../elements/spacer-vertical';

import InputText from '../input-text';
import PolicyTerms from '../policy-terms';
import * as channel from '../../config/constants/channel';
import PhoneMask from '../../masks/phone';

type Props = {
  phone?: string;
  loading?: boolean;
  onConfirm(phone: string): void;
  selectOther(): void;
};

export function WhatsappConfirmation(props: Props) {
  const [phone, setPhone] = useState(props.phone ?? '');
  const { t } = useTranslation();

  const error = !PhoneMask.validate(phone);

  const handlePhoneChange = (newPhone: string) => setPhone(newPhone);

  const handleConfirm = () => {
    if (phone && PhoneMask.validate(phone)) {
      props.onConfirm(`55${phone}`);
    }
  };

  return (
    <ContainerVertical fullWidth justify="flex-start">
      <ResponsiveHSpacer mobileSpace="20px" defaultSpace="0" />
      <ContainerHorizontal justify="flex-start" align="flex-start" fullWidth>
        <ButtonTransparent onClick={props.selectOther} id="trakr-whatsapp-confirmation-back-btn">
          <ContainerHorizontal>
            <LeftIcon />
            {t('BACK')}
          </ContainerHorizontal>
        </ButtonTransparent>
      </ContainerHorizontal>

      <HSpacer space="20px" />
      <ContainerVertical fullWidth>
        <InputText
          id="phone-input"
          name="phone-input"
          label={t('CELL_PHONE_WITH_DDD')}
          placeholder={t('CELL_PHONE_WITH_DDD_PLACEHOLDER')}
          value={phone}
          onChange={handlePhoneChange}
          maxLength={11}
          format={PhoneMask.maskWithoutCountry}
          unformat={PhoneMask.unmask}
          error={error}
        />

        <HSpacer space="15px" />

        <Button
          id="trakr-whatsapp-confirm-btn"
          background={colors.whatsapp}
          onClick={handleConfirm}
          disabled={props.loading || error}
        >
          <ContainerHorizontal spaceBetweenChild="10px">
            <WhatsAppIcon background={colors.buttonForeground} />
            <VSpacer space="10px" />
            {t('CONFIRM')}
          </ContainerHorizontal>
        </Button>

        <HSpacer space="15px" />

        <PolicyTerms />
      </ContainerVertical>
    </ContainerVertical>
  );
}

const mapState = (state: StateModel) => ({
  phone: state.customer.phone?.slice(2),
  loading: state.app.loading,
});

const mapDispatch = (dispatch: (action: IReduxAction) => unknown) => ({
  onConfirm: (phone: string) =>
    dispatch(confirmChannelSelection({ channel: channel.WHATSAPP, data: { phone } })),
  selectOther: () => dispatch(selectChannel()),
});

export default connect(mapState, mapDispatch)(WhatsappConfirmation);
