import { IReduxAction } from '../../interfaces/redux-action';
import { AppMode } from '../state-model';

export const getInitialData = (): IReduxAction => ({
  type: 'ASYNC_GET_INITIAL_DATA',
});

export const setApplicationLoading = (loading: boolean): IReduxAction => ({
  type: 'SET_APPLICATION_LOADING',
  payload: loading,
});

export const setApplicationReady = (ready: boolean): IReduxAction => ({
  type: 'SET_APPLICATION_READY',
  payload: ready,
});

export const setApplicationMode = (mode: AppMode): IReduxAction => ({
  type: 'SET_APPLICATION_MODE',
  payload: mode,
});

export const setApplicationOrder = (order: string): IReduxAction => ({
  type: 'SET_APPLICATION_ORDER',
  payload: order,
});

export const setApplicationOrderConfirmation = (confirmation: string): IReduxAction => ({
  type: 'SET_APPLICATION_ORDER_CONFIRMATION',
  payload: confirmation,
});

export const bootstrapApp = (order?: string, confirmation?: string): IReduxAction => ({
  type: 'ASYNC_BOOTSTRAP_APP',
  payload: { order, confirmation },
});

export const setApplicationEmail = (email: string): IReduxAction => ({
  type: 'SET_APPLICATION_EMAIL',
  payload: email,
});

export const setApplicationXhost = (xhost: string): IReduxAction => ({
  type: 'SET_APPLICATION_XHOST',
  payload: xhost,
});

export const setApplicationServer = (server: string): IReduxAction => ({
  type: 'SET_APPLICATION_SERVER',
  payload: server,
});

export const setApplicationPoolingInterval = (intervalMS: number) => ({
  type: 'SET_APPLICATION_POOLING_INTERVAL',
  payload: intervalMS,
});
