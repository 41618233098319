import React from 'react';

import PageContainer from '../elements/page-container';
import Container from '../elements/container';
import ContainerVertical from '../elements/container-vertical';
import AbsoluteRight from '../elements/position/absolute-right';
import LogoContainer from '../elements/logo-container';
import EventList from '../components/events/event-list';
import TrackrLogoIcon from '../resources/icons/trackr-logo';
import PoweredByIcon from '../resources/icons/powered-by';
import TrackingTitle from '../components/tracking-title';

export default function TrackingPage() {
  return (
    <ContainerVertical custom>
      <PageContainer>
        <ContainerVertical justify="flex-start" align="flex-start" fullWidth>
          <TrackingTitle />
          <EventList />
        </ContainerVertical>
      </PageContainer>

      <Container fullWidth height="26px" custom>
        <AbsoluteRight>
          <LogoContainer>
            <PoweredByIcon color="#9B9B9B" />
            <TrackrLogoIcon />
          </LogoContainer>
        </AbsoluteRight>
      </Container>
    </ContainerVertical>
  );
}
