import styled from 'styled-components';
import colors from '../resources/colors';
import sizes from '../resources/sizes';

interface IText {
  color?: string;
  size?: string;
  lineHeight?: string;
  weight?: string;
  allCaps?: boolean;
  center?: boolean;
}

const Text = styled.p`
  color: ${(props: IText) => (props.color ? props.color : colors.titleForeground)};
  font-size: ${(props: IText) => (props.size ? props.size : sizes.textFontSize)};
  line-height: ${(props: IText) => (props.lineHeight ? props.lineHeight : sizes.textLineHeight)};
  font-style: normal;
  font-weight: ${(props: IText) => (props.weight ? props.weight : 'normal')};
  text-transform: ${(props: IText) => (props.allCaps ? 'uppercase' : 'inherit')};
  text-align: ${(props: IText) => (props.center ? 'center' : 'inherit')};

  @media screen and (max-width: ${sizes.maxWidthMobile}) {
    text-align: start;
  }
`;

export default Text;
