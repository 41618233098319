import React from 'react';
import styled from 'styled-components';


import sizes from '../resources/sizes';
import colors from '../resources/colors';

interface Props {
  className?: string;
  children: any;
  custom?: boolean;
}

interface IPageContainer {
  background?: string;
  border?: string;
  padding?: string;
}

const page = (props: Props) => (
  <div className={`${props.className} ${props.custom ? 'trakr__v-container__page' : ''}`}>
    {props.children}
  </div>
);

const PageContainer = styled(page)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props: IPageContainer) =>
    props.background ? props.background : colors.appBackground};
  border: 2px solid ${(props: IPageContainer) => (props.border ? props.border : colors.appBorder)};
  padding: ${(props: IPageContainer) =>
    props.padding ? props.padding : sizes.pageContainerPadding};
  margin: 0;
  border-radius: 4px;

  * {
    margin: 0;
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }
`;

export default PageContainer;
