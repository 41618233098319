import React from 'react';
import { AppMode, StateModel } from './store/state-model';
import * as mode from './config/constants/mode';
import { ChannelSelectionPage } from './pages/channel-selection';
import TrackingPage from './pages/tracking';
import { connect } from 'react-redux';

type Props = {
  ready: boolean;
  mode: AppMode;
};

function AppRouter(props: Props) {
  if (!props.ready) {
    return <></>;
  }

  switch (props.mode) {
    case mode.NOT_READY:
      return <></>;
    case mode.CHOOSE_CHANNEL:
      return <ChannelSelectionPage />;
    case mode.TRACKING:
      return <TrackingPage />;
    default:
      return <></>;
  }
}

const mapState = (state: StateModel) => ({
  ready: state.app.ready,
  mode: state.app.mode,
});

export default connect(mapState)(AppRouter);
