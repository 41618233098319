import { useDispatch, useSelector } from 'react-redux';
import { setTrackingListExpanded } from '../store/actions/tracking';
import { StateModel } from '../store/state-model';

export function useTimelineExpanded(): [boolean, (arg: boolean) => void] {
  const expanded = useSelector((state: StateModel) => state.tracking.trackingExpanded || false);
  const dispatch = useDispatch();

  const setExpanded = (expanded: boolean) => dispatch(setTrackingListExpanded(expanded));

  return [expanded, setExpanded];
}
