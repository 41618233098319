import { MountStrategy } from './mount-strategy';
import vtexMountStrategy from './strategies/vtex-strategy';
import shopifyMountStrategy from './strategies/shopify-strategy';
import trayCommerceMountStrategy from './strategies/tray-commerce-strategy';
import magentoV1MountStrategy from './strategies/magentov1-strategy';

type AvailablePlatforms = 'vtex' | 'shopify' | 'tray_commerce' | 'magento_v1';

/**
 * Map each platform to an strategy to mount and unmount the app.
 */
export const strategyMountMap: { [k in AvailablePlatforms]: MountStrategy } = {
  vtex: vtexMountStrategy,
  shopify: shopifyMountStrategy,
  tray_commerce: trayCommerceMountStrategy,
  magento_v1: magentoV1MountStrategy,
};

export default function mountStrategyFactory(platform: AvailablePlatforms) {
  return strategyMountMap[platform];
}
