import { IReduxAction } from '../../interfaces/redux-action';

export interface CustomerState {
  email: string;
  phone?: string;
}

const initialState: CustomerState = {
  email: '',
};

export default function customer(state = initialState, action: IReduxAction) {
  switch (action.type) {
    case 'SET_CUSTOMER':
      return { ...state, ...action.payload };
    case 'SET_CUSTOMER_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_CUSTOMER_PHONE':
      return { ...state, phone: action.payload };
    default:
      return state;
  }
}
