import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Languages
import ptBR from '../lang/pt-BR';

const i18nInstance = i18n.use(initReactI18next).init({
  resources: {
    ptBR,
  },
  lng: 'ptBR',
  fallbackLng: 'ptBR',
  interpolation: {
    escapeValue: false,
  },
});

export default i18nInstance;
