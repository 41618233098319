import { MountConfigType, MountLocation } from './config-types';

export default class EntrypointConfig {
  private readonly defaultClasses: MountConfigType;

  private readonly defaultStyles: MountConfigType;

  constructor(defaultClasses: MountConfigType, defaultStyles: MountConfigType) {
    this.defaultClasses = defaultClasses;
    this.defaultStyles = defaultStyles;
  }

  getClasses(location: MountLocation) {
    return (window as any).TRAKR_EXTRA_CLASSES_MAP
      && (window as any).TRAKR_EXTRA_CLASSES_MAP[location]
      ? (window as any).TRAKR_EXTRA_CLASSES_MAP[location]
      : this.defaultClasses[location];
  }

  getStyles(location: MountLocation) {
    return (window as any).TRAKR_EXTRA_STYLES_MAP
      && (window as any).TRAKR_EXTRA_STYLES_MAP[location]
      ? (window as any).TRAKR_EXTRA_STYLES_MAP[location]
      : this.defaultStyles[location];
  }
}
