/* eslint-disable max-len */
const ptBR = {
  translation: {
    // Atoms
    APP_NAME: 'Rastreia Fácil',
    SHIP_UNTIL: 'Entrega até',
    TRACKING_CODE: 'Código de rastreio',
    FB_MESSENGER: 'Facebook Messenger',
    TELEGRAM: 'Telegram',
    WHATSAPP: 'WhatsApp',
    BACK: 'Voltar',
    CONNECT: 'Conectar',
    REGISTER: 'Registrar',
    CONFIRM: 'Confirmar',
    TRACKING_LINK: 'Link de rastreio',
    DELIVERY_NOTIFICATIONS: 'Notificações de entrega',
    TEXT_MESSAGE: 'Mensagem de texto',
    CELL_PHONE_WITH_DDD: 'Telefone com DDD',
    CELL_PHONE_WITH_DDD_PLACEHOLDER: 'Ex.: 41 99999-9999',
    NOT_BE_NOTIFIED: 'Não quero ser notificado',
    RECEIVE_NOTIFICATIONS: 'Receber notificações',
    SELECT_OTHER_CHANNEL: 'Selecionar outro canal',
    WAITING_NEW_EVENT: 'Aguardando atualização',
    ACTION_PICKED: 'Já retirei',
    ACTION_NEED_HELP: 'Ajuda',
    ACTION_NOT_RECEIVED: 'Não recebi',
    ACTION_RECEIVED: 'Ja recebi',
    ACTION_CHANGE_CHANNEL: 'Selecionar outro canal',
    ACTION_SELECT_CHANNEL: 'Selecionar canal',
    POLICY_TERMS_LABEL: 'Política de Privacidade',
    USE_TERMS_LABEL: 'Termos de Uso',
    SHOW_MORE: 'Ver todas as atualizações ({{events_length}})',
    SHOW_LESS: 'Ver menos',
    EVENT_CHANNEL_SELECTED: 'Canal selecionado',
    EVENT_CHANNEL_SMS_SELECTED:
      'Você receberá mensagens no seu celular {{phone}} referente às atualizações do seu pedido',
    EVENT_CHANNEL_MESSENGER_SELECTED:
      'Você receberá novas atualizações do seu pedido diretamente no Facebook Messenger.',
    EVENT_NO_CHANNEL_SELECTED:
      'Você escolheu não receber notificações de atualizações do seu pedido.',
    NO_NOTIFICATIONS: 'Sem notificações',
    TRACKING: 'Rastreio',
    AND: 'e',
    WAITING_MORE_INFO: 'Aguardando mais informações',
    SEND: 'Enviar',
    COMMENT: 'Comentário',

    // Messages
    WE_KEEP_YOU_UPDATED:
      'Escolha seu canal de comunicação favorito e vamos te informar sobre todas as etapas de seu pedido.',
    WHATSAPP_CONNECT_CONFIRMATION:
      'Confirme e envie a mensagem padrão para receber as próximas notificações pelo canal selecionado',
    FB_MESSENGER_CONNECT_CONFIRMATION:
      'Faça login no Facebook e mande um Oi para receber as próximas notificações pelo Messenger',
    SMS_CONNECT_CONFIRMATION:
      'Insira seu número de telefone e confirme para receber as próximas notificações via SMS',
    WAITING_NEW_EVENT_MESSAGE: 'Seu rastreio será disponibilizado em breve. Por favor, aguarde.',
    POLICY_TERMS_MESSAGE: 'Ao continuar você aceita os',

    // Asks
    HOW_TO_BE_NOTIFIED: 'Como você gostaria de ser notificado?',
    RECEIVE_UPDATES_WITH_WHATSAPP: 'Receber as atualizações pelo WhatsApp?',
    RECEIVE_UPDATES_WITH_FB_MESSENGER: 'Receber as atualizações pelo Facebook Messenger?',
    RECEIVE_UPDATES_WITH_SMS: 'Receber as atualizações por mensagem de texto?',
  },
};

export default ptBR;
