/**
 * Redux action creator for
 * setting the current order state.
 *
 * @param status Order state
 */
export const setOrderStatus = (status: string) => ({
  type: 'SET_ORDER_STATUS',
  payload: status,
});

/**
 * Redux action creator for resetting
 * the current order state.
 */
export const resetOrderState = () => ({
  type: 'RESET_ORDER_STATE',
});

/**
 * Set the tracking url to the redux store
 *
 * @param trackingUrl
 */
export const setOrderTrackingUrl = (trackingUrl: string) => ({
  type: 'SET_ORDER_TRACKING_URL',
  payload: trackingUrl,
});
