import styled from 'styled-components';

type Props = {
  accent: string;
};

const IconContainerRounded = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props: Props) => props.accent};
  margin-right: 20px !important;
`;

export default IconContainerRounded;
