import { AppMode } from '../state-model';
import { IReduxAction } from '../../interfaces/redux-action';

import * as mode from '../../config/constants/mode';

export interface AppState {
  ready: boolean;
  loading: boolean;
  mode: AppMode;
  server: string;
  order?: string;
  email?: string;
  demo?: boolean;
  xhost?: string;
  confirmation?: string;
  poolingInterval?: number;
}

const initialState: AppState = {
  ready: false,
  loading: false,
  mode: mode.NOT_READY,
  order: undefined,
  email: undefined,
  demo: false,
  server: '',
  xhost: undefined,
};

export default function app(state: AppState = initialState, action: IReduxAction): AppState {
  switch (action.type) {
    case 'SET_APPLICATION_READY':
      return { ...state, ready: action.payload };
    case 'SET_APPLICATION_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_APPLICATION_MODE':
      return { ...state, mode: action.payload };
    case 'SET_APPLICATION_ORDER':
      return { ...state, order: action.payload };
    case 'SET_APPLICATION_ORDER_CONFIRMATION':
      return { ...state, confirmation: action.payload };
    case 'SET_APPLICATION_SERVER':
      return { ...state, server: action.payload };
    case 'SET_APPLICATION_DEMO_MODE':
      return { ...state, demo: action.payload };
    case 'SET_APPLICATION_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_APPLICATION_XHOST':
      return { ...state, xhost: action.payload };
    case 'SET_APPLICATION_POOLING_INTERVAL':
      return { ...state, poolingInterval: action.payload };
    default:
      return state;
  }
}
