import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TrackingLink from './tracking-link';
import HugeTitle from '../elements/huge-title';
import HSpacer from '../elements/spacer-horizontal';
import { StateModel } from '../store/state-model';

type Props = {
  trackingUrl?: string;
};

export function TrackingTitle(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <HugeTitle>{t('TRACKING')}</HugeTitle>
      <TrackingLink trackingUrl={props.trackingUrl} />

      <HSpacer space="20px" />
    </>
  );
}

const mapState = (state: StateModel) => ({
  trackingUrl: state.order.trackingUrl,
});

export default connect(mapState)(TrackingTitle);
