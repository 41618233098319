import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children: any;
  custom?: boolean;
}

interface IContainerHorizontal {
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  align?: 'center' | 'flex-start' | 'flex-end';
  spaceBetweenChild?: string;
  height?: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  maxWidth?: string;
}

const hContainer = (props: Props) => (
  <div className={`${props.className} ${props.custom ? 'trakr__h-container' : ''}`}>
    {props.children}
  </div>
);

const ContainerHorizontal = styled(hContainer)`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: ${(props: IContainerHorizontal) => (props.justify ? props.justify : 'center')};
  align-items: ${(props: IContainerHorizontal) => (props.align ? props.align : 'center')};
  height: ${(props: IContainerHorizontal) =>
    (props.height ? props.height : props.fullHeight ? '100%' : 'inherit')};
  width: ${(props: IContainerHorizontal) => (props.fullWidth ? '100%' : 'inherit')};
  max-width: ${(props: IContainerHorizontal) => (props.maxWidth || 'none')};
`;

export default ContainerHorizontal;
