/* eslint-disable max-len */
import React from 'react';
import colors from '../colors';

interface ISMSIcon {
  background?: string;
}

export default function SMSIcon(props: ISMSIcon) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 1.25C4.47656 1.25 0 4.88672 0 9.375C0 11.3125 0.835938 13.0859 2.22656 14.4805C1.73828 16.4492 0.105469 18.2031 0.0859375 18.2227C0 18.3125 -0.0234375 18.4453 0.0273437 18.5625C0.078125 18.6797 0.1875 18.75 0.3125 18.75C2.90234 18.75 4.84375 17.5078 5.80469 16.7422C7.08203 17.2227 8.5 17.5 10 17.5C15.5234 17.5 20 13.8633 20 9.375C20 4.88672 15.5234 1.25 10 1.25ZM5 10.625C4.30859 10.625 3.75 10.0664 3.75 9.375C3.75 8.68359 4.30859 8.125 5 8.125C5.69141 8.125 6.25 8.68359 6.25 9.375C6.25 10.0664 5.69141 10.625 5 10.625ZM10 10.625C9.30859 10.625 8.75 10.0664 8.75 9.375C8.75 8.68359 9.30859 8.125 10 8.125C10.6914 8.125 11.25 8.68359 11.25 9.375C11.25 10.0664 10.6914 10.625 10 10.625ZM15 10.625C14.3086 10.625 13.75 10.0664 13.75 9.375C13.75 8.68359 14.3086 8.125 15 8.125C15.6914 8.125 16.25 8.68359 16.25 9.375C16.25 10.0664 15.6914 10.625 15 10.625Z" fill={props.background ? props.background : colors.sms} />
    </svg>
  );
}
