import React from 'react';
import styled from 'styled-components';

interface Props {
  id?: string;
  className?: string;
  children: any;
  onClick(): void;
}

const button = (props: Props) => (
  <button
    type="button"
    className={`${props.className} trakr__expand-button`}
    {...props} // eslint-disable-line
  >
    {props.children}
  </button>
);

const ButtonTransparent = styled(button)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export default ButtonTransparent;
