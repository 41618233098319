export const MISTER_S_URL = process.env.MISTER_S_URL as string;

const demo = process.env.DEMO_SERVER_URL as string;
const sentry = process.env.SENTRY_DSN as string;

const endpoints = {
  sentry,
  demo,
  tracking: '/tracking',
  channel: '/channel',
  action: '/action',
  policy: 'https://www.trakr.com.br/politica-de-privacidade-e-termos-de-aceite',
};

export default endpoints;
