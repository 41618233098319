import styled from 'styled-components';
import colors from '../resources/colors';

const ButtonLink = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${colors.textForeground};
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

export default ButtonLink;
