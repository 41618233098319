export default class ShopifyOrderExtrator {
  /**
   * Extract the order number from the DOM in the public order page
   * (That accessible from email link)
   *
   * @param doc
   * @param selector
   */
  static extractFromPublicOrder(doc: Document, selector: string): string | undefined {
    const elem = doc.querySelector(selector);
    if (!elem) return undefined;

    return ShopifyOrderExtrator.extractUsingRegex(elem.innerHTML, /([0-9]+)/g);
  }

  /**
   * Extract the order number from the DOM in the order page
   *
   * @param doc
   * @param selector
   */
  static extractFromOrder(doc: Document, selector: string): string | undefined {
    const elem = doc.querySelector(selector);
    if (!elem) return undefined;

    return ShopifyOrderExtrator.extractUsingRegex(elem.innerHTML, /([0-9]+)/g);
  }

  /**
   * Extract the order from the DOM in the checkout page
   * (It uses the same template that the public order page)
   *
   * @param doc
   * @param selector
   */
  static extractFromCheckout(doc: Document, selector: string): string | undefined {
    return ShopifyOrderExtrator.extractFromPublicOrder(doc, selector);
  }

  /**
   * Extract the order number from the given text using the given regex
   *
   * @param text
   * @param rx
   */
  static extractUsingRegex(text: string, rx: RegExp) {
    const regexResult = rx.exec(text);
    return regexResult && regexResult.length > 1 ? regexResult[1] : undefined;
  }
}
