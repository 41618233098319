import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IActionButton } from './shared';
import Button from '../../elements/button';
import ContainerVertical from '../../elements/container-vertical';
import ContainerHorizontal from '../../elements/container-horizontal';
import HSpacer from '../../elements/spacer-horizontal';
import colors from '../../resources/colors';
import TextArea from '../textarea';
import { IReduxAction } from '../../interfaces/redux-action';
import { doAction } from '../../store/actions/tracking';
import { StateModel } from '../../store/state-model';

import * as trackingActions from '../../config/constants/tracking-actions';

export function NecessaryAction(props: IActionButton) {
  const [text, setText] = useState('');
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const handleActionValidate = () => {
    const trimmedText = text.trim();

    if (trimmedText) {
      setError(false);
      props.onAction({
        name: trackingActions.NECESSARY_ACTION,
        informedData: trimmedText,
      });
    } else {
      setError(true);
      setText('');
    }
  };

  return (
    <ContainerHorizontal>
      <ContainerVertical justify="center" align="flex-start" minHeight="160px" fullWidth>
        <TextArea
          id="textarea-action"
          name="textarea-action"
          placeholder=""
          value={text}
          label={t('COMMENT')}
          error={error}
          onChange={(value) => setText(value)}
        />

        <HSpacer space="15px" />

        <ContainerHorizontal maxWidth="160px">
          <Button
            id={trackingActions.NECESSARY_ACTION}
            disabled={props.loading}
            background={colors.green}
            color={colors.buttonForeground}
            onClick={() => handleActionValidate()}
            margin="0 8px 0 0"
          >
            {t('SEND')}
          </Button>
        </ContainerHorizontal>
      </ContainerVertical>
    </ContainerHorizontal>
  );
}

const mapState = (state: StateModel) => ({
  loading: state.app.loading,
});

const mapDispatch = (dispatch: (action: IReduxAction) => void) => ({
  onAction: (payload: any) => dispatch(doAction(payload)),
});

export default connect(mapState, mapDispatch)(NecessaryAction);
