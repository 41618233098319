import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';

import './config/i18n';
import { IReduxAction } from './interfaces/redux-action';
import {
  bootstrapApp,
  setApplicationOrder,
  setApplicationOrderConfirmation,
  setApplicationPoolingInterval,
  setApplicationServer,
  setApplicationXhost,
} from './store/actions/app';
import AppRouter from './app-router';

import { sentry } from './config/endpoints';

Sentry.init({ dsn: sentry });

interface IApp {
  order?: string;
  confirmation?: string;
  server?: string;
  xhost?: string;
  poolingInterval?: number;
  init(order?: string, confirmation?: string): void;
  setOrder(order: string): void;
  setOrderConfirmation(confirmation: string): void;
  setServer(server: string): void;
  setXhost(xhost: string): void;
  setPoolingInterval(intervalMs: number): void;
}

const App = (props: IApp) => {
  useEffect(() => {
    if (!!props.server) props.setServer(props.server);
    if (!!props.xhost) props.setXhost(props.xhost);
    if (!!props.order) props.setOrder(props.order);
    if (!!props.confirmation) props.setOrderConfirmation(props.confirmation);
    if (!!props.poolingInterval) props.setPoolingInterval(props.poolingInterval);

    // init the app only if an order is provided.
    if (!!props.order) props.init(props.order, props.confirmation);
  }, [props.order, props.confirmation, props.server, props.xhost]);

  return (
    <Sentry.ErrorBoundary
      fallback={<div data-testid="tracking-module-error" />}
      onError={console.error}
    >
      <AppRouter />
    </Sentry.ErrorBoundary>
  );
};

const mapDispatch = (dispatch: (action: IReduxAction) => unknown) => ({
  setOrder: (order: string) => dispatch(setApplicationOrder(order)),
  setOrderConfirmation: (confirmation: string) =>
    dispatch(setApplicationOrderConfirmation(confirmation)),
  setServer: (server: string) => dispatch(setApplicationServer(server)),
  setXhost: (xhost: string) => dispatch(setApplicationXhost(xhost)),
  setPoolingInterval: (poolingInterval: number) =>
    dispatch(setApplicationPoolingInterval(poolingInterval)),
  init: (order: string, confirmation?: string) => dispatch(bootstrapApp(order, confirmation)),
});

export default connect(null, mapDispatch)(App);
