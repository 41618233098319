import styled from 'styled-components';
import colors from '../resources/colors';

interface Props {
  position?: string;
}

const StepperConnector = styled.hr<Props>`
  position: ${(props: Props) => props.position ?? 'relative'};
  width: 0;
  border: 0;
  border-right: 2px dashed ${colors.divider};
  height: 100%;
  margin: 0;
  flex: auto;
`;

export default StepperConnector;
