import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IActionButton } from './shared';
import Button from '../../elements/button';
import colors from '../../resources/colors';
import { IReduxAction } from '../../interfaces/redux-action';
import { doAction } from '../../store/actions/tracking';
import { StateModel } from '../../store/state-model';

import * as trackingActions from '../../config/constants/tracking-actions';

export function ActionHelp(props: IActionButton) {
  const { t } = useTranslation();

  return (
    <Button
      id={trackingActions.HELP}
      disabled={props.loading}
      background={colors.royal}
      color={colors.buttonForeground}
      onClick={() => props.onAction()}
      margin="0 8px 0 0"
    >
      {t('ACTION_NEED_HELP')}
    </Button>
  );
}

const mapState = (state: StateModel) => ({
  loading: state.app.loading,
});

const mapDispatch = (dispatch: (action: IReduxAction) => void) => ({
  onAction: () => dispatch(doAction({ name: trackingActions.HELP })),
});

export default connect(mapState, mapDispatch)(ActionHelp);
