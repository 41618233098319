import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { createInitialState } from './initial-state';
import root from './sagas/root';
import createReducer from './reducers/root';
import { AppState } from './reducers/app';

type CreateStoreOpts = {
  server: string;
  xhost?: string;
  poolingInterval?: number;
};

function configureStore() {
  return function (opts?: CreateStoreOpts, partialState?: Partial<AppState>) {
    const initialState = createInitialState(partialState);
    opts && opts.server && (initialState.app.server = opts.server);
    opts && opts.xhost && (initialState.app.xhost = opts.xhost);
    opts && opts.poolingInterval && (initialState.app.poolingInterval = opts.poolingInterval);

    const sagaMiddleware = createSagaMiddleware();

    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
      createReducer(),
      initialState,
      composeEnhancers(applyMiddleware(sagaMiddleware))
    );

    const { cancel } = sagaMiddleware.run(root);

    return { store, cancel };
  };
}

export default configureStore();
