import styled from 'styled-components';
import sizes from '../resources/sizes';
import colors from '../resources/colors';

const TextDescription = styled.p`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.descriptionForeground};

  @media screen and (max-width: ${sizes.maxWidthMobile}) {
    text-align: start;
  }
`;

export default TextDescription;
