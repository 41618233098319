import styled from 'styled-components';

const LogoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  width: 95px;

  @media screen and (max-width: 480px) {
    svg:first-of-type {
      visibility: hidden;
    }
  }
`;

export default LogoContainer;
