export default class MagentoV1OrderExtractor {
  /**
   * Extract the order from order details
   *
   * @param doc
   * @param selector
   */
  static extractFromAccountOrder(doc: Document, selector: string): string | undefined {
    const elem = doc.querySelector(selector);
    if (!elem) return undefined;

    return this.extractUsingRegex(elem.innerHTML, /([\d]+)/g);
  }

  /**
   * Extract the order number from the checkout page
   *
   * @param doc
   * @param selector
   */
  static extractFromCheckout(doc: Document, selector: string): string | undefined {
    const elem = doc.querySelector(selector);
    if (!elem) return undefined;

    return this.extractUsingRegex(elem.innerHTML, /([\d]+)/g);
  }

  static extractUsingRegex(text: string, regexp: RegExp) {
    // reset the regex index to prevent errors
    // eslint-disable-next-line no-param-reassign
    regexp.lastIndex = 0;
    const regexResult = regexp.exec(text);
    return regexResult && regexResult.length > 1 ? regexResult[1] : undefined;
  }
}
