import { put, call, select, delay } from 'redux-saga/effects';

import { setApplicationLoading, bootstrapApp } from '../actions/app';

import { StateModel } from '../state-model';
import { fetchData } from './network';

import endpoints from '../../config/endpoints';
import * as channel from '../../config/constants/channel';

import { IReduxAction } from '../../interfaces/redux-action';
import { IChannel } from '../../interfaces/channel';
import { createRedirectLink } from '../../utils/whatsapp';
import { bypassChannelChecking } from '../actions/channel';

function* asyncFacebookChannelSelection() {
  const { order, options, email } = yield select((state: StateModel) => ({
    order: state.app.order,
    options: state.channel.options,
    email: state.app.email,
  }));

  const fbOption = options.find((option: IChannel) => option.id === channel.MESSENGER) as IChannel;
  if (fbOption.data.page_id) {
    const ref = JSON.stringify({ order, email });
    window.open(`https://m.me/${fbOption.data.page_id}?ref=${ref}`);
  }
}

function* asyncNotDisturbSelection(payload: any) {
  const { order, confirmation, xhost, server } = yield select((state: StateModel) => ({
    order: state.app.order,
    confirmation: state.app.confirmation,
    xhost: state.app.xhost,
    server: state.app.server,
  }));

  const response = yield fetchData({
    url: `${server + endpoints.channel}?order=${order}${
      confirmation ? '&confirmation=' + confirmation : ''
    }`,
    method: 'POST',
    payload,
    xhost,
  });

  if (!(response instanceof Error)) {
    yield put(bootstrapApp());
  }
}

function* asyncSMSChannelSelection(payload: object) {
  const { order, confirmation, xhost, server } = yield select((state: StateModel) => ({
    order: state.app.order,
    confirmation: state.app.confirmation,
    xhost: state.app.xhost,
    server: state.app.server,
  }));

  try {
    if (typeof order === 'string') {
      const response = yield fetchData({
        url: `${server + endpoints.channel}?order=${order}${
          confirmation ? '&confirmation=' + confirmation : ''
        }`,
        method: 'POST',
        payload,
        xhost,
      });

      const channelSelectionConfirmed = !(response instanceof Error);

      if (channelSelectionConfirmed) {
        yield put(bootstrapApp());
      }
    }
  } catch (error) {
    yield put({ type: 'ERROR', error });
  } finally {
    yield delay(200);
    yield put(setApplicationLoading(false));
  }
}

function* asyncWhatsappChannelSelection(payload: object) {
  const { order, confirmation, options, xhost, server } = yield select((state: StateModel) => ({
    order: state.app.order,
    confirmation: state.app.confirmation,
    options: state.channel.options,
    xhost: state.app.xhost,
    server: state.app.server,
  }));

  try {
    if (typeof order === 'string') {
      const response = yield fetchData({
        url: `${server + endpoints.channel}?order=${order}${
          confirmation ? '&confirmation=' + confirmation : ''
        }`,
        method: 'POST',
        payload,
        xhost,
      });

      const channelSelectionConfirmed = !(response instanceof Error);

      const whatsappChannel = options.find(
        (option: IChannel) => option.id === channel.WHATSAPP
      ) as IChannel;
      if (whatsappChannel.data.phone_number) {
        const url = createRedirectLink(
          whatsappChannel.data.phone_number,
          whatsappChannel.data.greeting.replace('{order_id}', order)
        );
        window.open(url);
      }

      if (channelSelectionConfirmed) {
        yield put(bootstrapApp());
      }
    }
  } catch (error) {
    yield put({ type: 'ERROR', error });
  } finally {
    yield delay(200);
    yield put(setApplicationLoading(false));
  }
}

export function* asyncSelectChannel(action: IReduxAction) {
  yield put(setApplicationLoading(true));
  const payload = action.payload as { channel: string; data: any };

  if (payload && payload.channel === channel.MESSENGER) {
    yield call(asyncFacebookChannelSelection);
  }

  if (payload && payload.channel === channel.SMS) {
    yield call(asyncSMSChannelSelection, payload);
  }

  if (payload && payload.channel === channel.WHATSAPP) {
    yield call(asyncWhatsappChannelSelection, payload);
  }

  if (payload && payload.channel === channel.NOT_DISTURB) {
    yield put(bypassChannelChecking(true));
    yield call(asyncNotDisturbSelection, payload);
  }
}
