import { combineReducers } from 'redux';
import app from './app';
import channel from './channel';
import tracking from './tracking';
import order from './order';
import ecommerce from './ecommerce';
import customer from './customer';

export default function createReducer() {
  return combineReducers({
    app,
    channel,
    tracking,
    order,
    ecommerce,
    customer,
  });
}
