import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import colors from '../../resources/colors';
import { confirmChannelSelection, selectChannel } from '../../store/actions/channel';
import ContainerVertical from '../../elements/container-vertical';
import ContainerHorizontal from '../../elements/container-horizontal';
import Button from '../../elements/button';
import { IReduxAction } from '../../interfaces/redux-action';
import Messenger from '../../resources/icons/messenger';
import HSpacer from '../../elements/spacer-horizontal';
import { StateModel } from '../../store/state-model';
import VSpacer from '../../elements/spacer-vertical';
import PolicyTerms from '../policy-terms';

import * as channel from '../../config/constants/channel';
import LeftIcon from '../../resources/icons/left';
import ButtonTransparent from '../../elements/button-transparent';
import ResponsiveHSpacer from '../../elements/responsive-horizontal-spacer';

interface IMessengerConfirmation {
  onConfirm: () => void;
  selectOther: () => void;
  order: string;
}

export function MessengerConfirmation(props: IMessengerConfirmation) {
  const { t } = useTranslation();

  const handleFBMessengerSelect = () => {
    props.onConfirm();
  };

  return (
    <ContainerVertical fullWidth justify="flex-start">
      <ResponsiveHSpacer mobileSpace="20px" defaultSpace="0" />
      <ContainerHorizontal justify="flex-start" align="flex-start" fullWidth>
        <ButtonTransparent onClick={props.selectOther}>
          <ContainerHorizontal>
            <LeftIcon />
            {t('BACK')}
          </ContainerHorizontal>
        </ButtonTransparent>
      </ContainerHorizontal>

      <HSpacer space="20px" />

      <ContainerVertical fullWidth>
        <Button background={colors.facebook} onClick={handleFBMessengerSelect}>
          <ContainerHorizontal spaceBetweenChild="10px">
            <Messenger background={colors.buttonForeground} />
            <VSpacer space="10px" />
            {t('CONNECT')}
          </ContainerHorizontal>
        </Button>

        <HSpacer space="15px" />

        <PolicyTerms />
      </ContainerVertical>
    </ContainerVertical>
  );
}

const mapState = (state: StateModel) => ({
  order: state.app.order as string,
});

const mapDispatch = (dispatch: (action: IReduxAction) => unknown) => ({
  onConfirm: () => dispatch(confirmChannelSelection({ channel: channel.MESSENGER, data: '' })),
  selectOther: () => dispatch(selectChannel()),
});

export default connect(mapState, mapDispatch)(MessengerConfirmation);
