/* eslint-disable max-len */
import React from 'react';
import colors from '../colors';

interface ITrackrLogoIcon {
  color?: string;
}

export default function TrackrLogoIcon(props: ITrackrLogoIcon) {
  return (
    <a href="https://after.sale/rastreio" target="__blank" rel="noopener noreferrer">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" fill="none" viewBox="0 0 95 67.17">
        <title>aftersale</title>
        <g id="Camada_2" data-name="Camada 2">
          <g id="Layer_1" data-name="Layer 1">
            <path
              d="M26.2,33.53,42.82,16.91,27,1.13a3.83,3.83,0,0,0-5.44,0L1.13,21.59a3.83,3.83,0,0,0,0,5.44l5.42,5.43a3.88,3.88,0,0,0,2.73,1.13H26.15Z"
              fill={props.color ? props.color : colors.heartLightGray}
            />
            <path
              d="M40.06,66.05,19.59,45.58a3.85,3.85,0,0,1,0-5.44l6.61-6.61L58.61,1.13a3.83,3.83,0,0,1,5.44,0L84.51,21.59a3.83,3.83,0,0,1,0,5.44l-39,39A3.85,3.85,0,0,1,40.06,66.05Z"
              fill={props.color ? props.color : colors.heartGray}
            />
          </g>
        </g>
      </svg>
    </a>
  );
}
