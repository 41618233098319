export default class TrakrError {
  public error: string | Error;

  public dump?: object;

  constructor(error: string | Error, dump?: object) {
    this.error = error;
    this.dump = dump;
  }

  toString(): string {
    return JSON.stringify({
      error: this.error instanceof Error ? this.error.message : this.error,
      dump: this.dump,
    }, null, 2);
  }
}
