import styled from 'styled-components';

type Props = {
  color: string;
}

const StepperIconContainer = styled.div<Props>`
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  margin: 0;
  border-radius: 50%;
  background-color: ${(props: Props) => props.color};
  z-index: 2;
`;

export default StepperIconContainer;
