import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  trackingUrl?: string;
};

export default function TrackingLink(props: Props) {
  const { t } = useTranslation();

  if (!props.trackingUrl) {
    return <></>;
  }

  return (
    <p>
      {t('TRACKING_LINK')}
      :&nbsp;
      <a href={props.trackingUrl} target="_blank" rel="noopener noreferrer">
        {props.trackingUrl}
      </a>
    </p>
  );
}
