import React from 'react';
import styled from 'styled-components';

import colors from '../resources/colors';
import sizes from '../resources/sizes';

interface Props {
  id?: string;
  className?: string;
  children: any;
  onClick?: () => void;
}

interface IButton {
  color?: string;
  background?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  padding?: string;
  boxShadow?: string;
  justify?: string;
  align?: string;
  disableAllCaps?: boolean;
  margin?: string;
}

const button = (props: Props) => (
  <button
    type="button"
    className={`${props.className} trakr__channel__button`}
    {...props} // eslint-disable-line
  >
    {props.children}
  </button>
);

const Button = styled(button)`
  position: relative;
  display: flex;
  justify-content: ${(props: IButton) => props.justify ?? 'center'};
  align-items: ${(props: IButton) => props.align ?? 'center'};
  background: ${(props: IButton) => {
    if (props.disabled) {
      return colors.descriptionForeground;
    }

    return props.background ? props.background : colors.green;
  }};
  color: ${(props: IButton) => (props.color ? props.color : colors.buttonForeground)};
  border: none;
  border-radius: 32px;
  padding: ${(props: IButton) => (props.padding ? props.padding : sizes.buttonDefaultPadding)};
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-transform: ${(props: IButton) => (props.disableAllCaps ? 'normal' : 'uppercase')};
  cursor: pointer;
  width: ${(props: IButton) => (props.fullWidth ? '100vh' : '100%')};
  min-width: 144px;
  pointer-events: ${(props: IButton) => (props.disabled ? 'none' : 'all')};
  box-shadow: ${(props: IButton) => props.boxShadow ?? ''};
  margin: ${(props: IButton) => props.margin ?? '0'} !important;
`;

export default Button;
