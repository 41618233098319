import React from 'react';
import ContainerVertical from '../elements/container-vertical';
import Label from '../elements/label';
import Input from '../elements/input';
import HSpacer from '../elements/spacer-horizontal';
import Text from '../elements/text';

interface IInputText {
  id: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  label: string;
  placeholder: string;
  mask?: string;
  error?: boolean;
  maxLength?: number;
  onDisable?(value: boolean): void;
  format?(value: string): string;
  unformat?(value: string): string;
}

export default function InputText(props: IInputText) {
  const value = props.format ? props.format(props.value) : props.value;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = props.unformat ? props.unformat(event.target.value) : event.target.value;

    if (props.maxLength) {
      if (rawValue.length <= props.maxLength) {
        props.onChange(rawValue);
      }
    } else {
      props.onChange(rawValue);
    }
  };

  return (
    <ContainerVertical align="flex-start" custom input>
      <Label htmlFor={props.name}>
        <Text weight="bold" allCaps>
          {props.label}
        </Text>
      </Label>
      <HSpacer space="5px" />
      <Input
        type="text"
        name={props.name}
        id={props.id}
        placeholder={props.placeholder}
        value={value}
        onChange={handleChange}
        error={props.error}
      />
    </ContainerVertical>
  );
}
