import autoInit from './auto-init';
import manualInit from './manual-init';

// eslint-disable-next-line consistent-return
export function start(document: Document) {
  try {
    const scripts = document.getElementsByTagName('script');
    const script = Array.from(scripts).find((s) =>
      s.src.match(/trakr.com.br/));

    if (script) {
      const match = /\?manual=(true|false)/gi.exec(script.src);
      const isManual = match ? match[1] : undefined;
      if (isManual === 'true') {
        return manualInit();
      }
    }

    autoInit();
  } catch {
    // run the auto init in case of error
    // in some browsers the script is loaded before the
    // dom is ready
    autoInit();
  }
}

start(document);
