/* eslint-disable no-nested-ternary */
import React from 'react';
import Title from '../../elements/title';
import Text from '../../elements/text';
import colors from '../../resources/colors';
import { ITrackingEvent } from '../../interfaces/tracking-event';
import ContainerVertical from '../../elements/container-vertical';
import TextDescription from '../../elements/text-description';
import StepperConnector from '../../elements/stepper-connector';
import StepperSection from '../../elements/stepper-section';
import HSpacer from '../../elements/spacer-horizontal';
import StepperIconContainer from '../../elements/stepper-icon-container';
import ContainerHorizontal from '../../elements/container-horizontal';
import ActionPicked from '../actions/picked';
import ActionReceived from '../actions/received';
import ActionNotReceived from '../actions/not-received';
import ActionHelp from '../actions/help';
import ActionNecessaryAction from '../actions/necessary-action';
import ActionSelectChannel from '../actions/select-channel';

import * as eventCode from '../../config/constants/event-code';
import * as TrackingActions from '../../config/constants/tracking-actions';
import parseUrl from '../../parsers/parse-url';
import ActionChangeChannel from '../actions/change-channel';

export interface IEventItem {
  actions: string[];
  isLast?: boolean;
  isFirst?: boolean;
  disableConnector?: boolean;
  disableBottomSpacer?: boolean;
}

const selectActionButton = (action: string) => {
  switch (action) {
    case TrackingActions.PICKED:
      return <ActionPicked key={action} />;
    case TrackingActions.RECEIVED:
      return <ActionReceived key={action} />;
    case TrackingActions.NOT_RECEIVED:
      return <ActionNotReceived key={action} />;
    case TrackingActions.HELP:
      return <ActionHelp key={action} />;
    case TrackingActions.NECESSARY_ACTION:
      return <ActionNecessaryAction key={action} />;
    case TrackingActions.CHANGE_CHANNEL:
      return <ActionChangeChannel key={action} />;
    case TrackingActions.SELECT_CHANNEL:
      return <ActionSelectChannel key={action} />;
    default:
      return <></>;
  }
};

const selectDotColor = (code: string): string => {
  const num = parseInt(code, 10);

  if (num === eventCode.INIT) {
    return colors.lightGray;
  }

  if (
    num === eventCode.FAIL ||
    num === eventCode.COURIER_UNKNOWN ||
    num === eventCode.CANCELED ||
    num === eventCode.NECESSARY_ACTION
  ) {
    return colors.orange;
  }

  if (num === eventCode.CONTACT) {
    return colors.royal;
  }

  return colors.green;
};

export default function EventItem(props: IEventItem & ITrackingEvent) {
  const dateInstance = props.date !== 0 ? new Date(props.date) : undefined;
  const date = dateInstance ? dateInstance.toLocaleDateString('pt-BR') : '';
  const time = dateInstance ? dateInstance.toLocaleTimeString('pt-BR') : '';

  return (
    <StepperSection>
      <ContainerVertical
        align="center"
        justify="flex-start"
        padding={`${props.isFirst && props.isLast ? 9 : 27}px 0 0 0`}
      >
        <StepperIconContainer color={selectDotColor(props.code)} />
        {!props.disableConnector && (
          <StepperConnector position={props.isFirst ? 'relative' : 'absolute'} />
        )}
      </ContainerVertical>

      <ContainerVertical align="flex-start" padding="0 0 0 20px">
        {props.date !== 0 && (
          <Text>
            {date}
            {' - '}
            {time}
          </Text>
        )}
        <HSpacer space="2px" />
        <Title>{props.description}</Title>
        <HSpacer space="2px" />
        <TextDescription>
          <p dangerouslySetInnerHTML={{ __html: props.details ? parseUrl(props.details) : '' }} />
        </TextDescription>

        {props.actions.length > 0 && (
          <ContainerVertical fullWidth={parseInt(props.code, 10) === eventCode.NECESSARY_ACTION}>
            <HSpacer space="15px" />
            <ContainerHorizontal>
              {props.actions.map((action) => selectActionButton(action))}
            </ContainerHorizontal>
          </ContainerVertical>
        )}

        {!props.disableBottomSpacer && <HSpacer space="25px" />}
      </ContainerVertical>
    </StepperSection>
  );
}
