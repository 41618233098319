type PhoneMaskConfig = {
  excludeCountry?: boolean;
};

const defaultMaskConfig: PhoneMaskConfig = {
  excludeCountry: true,
};

/**
 * Phone mask is an BiFunctor providing the map (mask) and unmap (unmask) methods.
 */
export default class PhoneMask {
  static mask(phone: string, config: PhoneMaskConfig = defaultMaskConfig) {
    return config.excludeCountry
      ? phone.replace(/^(\d\d)(\d\d)(\d{5})(\d{4})/, '($2) $3-$4')
      : phone.replace(/^(\d\d)(\d\d)(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
  }

  static unmask(phone: string) {
    return phone.replace(/[^\d]+/g, '');
  }

  static maskWithoutCountry(phone: string) {
    return phone.replace(/^(\d\d)(\d{5})(\d{4})$/, '($1) $2-$3');
  }

  static validate(phone: string) {
    return /^(\d\d)(\d{5})(\d{4})$/.test(phone);
  }
}
