export const sentry = process.env.SENTRY_DSN as string;
export const commit = process.env.COMMIT_VERSION as string;

const endpoints = {
  tracking: '/tracking',
  channel: '/channel',
  action: '/action',
  policy: 'https://www.trakr.com.br/politica-de-privacidade-e-termos-de-aceite',
};

export default endpoints;
