import EntrypointConfig from '../mount-config/entrypoint-config';
import MountSelectorConfig from '../mount-config/mount-selector-config';
import { MountLocation } from '../mount-config/config-types';
import * as views from '../../config/view';
import { TRAKR_WIDGET_ELEMENT_ID } from '../mounter-helpers';
import VtexOrderExtractor from '../order-extractor/vtex-order-extractor';
import {
  defaultClassesConfig,
  defaultConfirmationExtractorConfig,
  defaultMountSelectorConfig,
  defaultStylesConfig,
} from '../defaults/vtex-defaults';
import { DefaultConfirmationExtractor } from '../confirmation-extractor/default-confirmation-extractor';
import ConfirmationExtractorConfig from '../mount-config/confirmation-extractor-config';

const mountLocations = {
  accountOrder: /orders\/([a-zA-Z0-9]+-[a-zA-Z-0-9]{2})/,
  checkout: /\/checkout\/orderPlaced\/\?og=([a-zA-Z0-9]+)/,
};

const entrypointConfig = new EntrypointConfig(defaultClassesConfig, defaultStylesConfig);
const mountSelectorConfig = new MountSelectorConfig(defaultMountSelectorConfig);
const confirmationExtractorConfig = new ConfirmationExtractorConfig(
  defaultConfirmationExtractorConfig,
);

export function createEntrypoint(location: MountLocation) {
  const entrypoint = document.createElement('div');
  entrypoint.setAttribute('id', TRAKR_WIDGET_ELEMENT_ID);
  // @ts-ignore
  entrypoint.style = entrypointConfig.getStyles(location) ?? '';
  const customClasses = entrypointConfig.getClasses(location);
  if (customClasses) {
    customClasses.split(' ').forEach((className: string) => entrypoint.classList.add(className));
  }
  return entrypoint;
}

export function determineLocation(url: string): MountLocation | undefined {
  // reset the regex pointers
  mountLocations.accountOrder.lastIndex = 0;
  mountLocations.checkout.lastIndex = 0;

  const accountOrder = mountLocations.accountOrder.test(url) ? views.ACCOUNT_ORDER : undefined;
  const checkout = mountLocations.checkout.test(url) ? views.CHECKOUT : undefined;

  return accountOrder ?? checkout ?? undefined;
}

export function getTarget(location: MountLocation) {
  return document.querySelector(mountSelectorConfig.getSelector(location));
}

export function extractOrder(location: MountLocation) {
  switch (location) {
    case views.CHECKOUT:
      return VtexOrderExtractor.extractFromOrderConfirmation(window.location.href);
    case views.ACCOUNT_ORDER:
      return VtexOrderExtractor.extractFromAccountOrder(window.location.href);
    default:
      return null;
  }
}

export function extractConfirmation(location: MountLocation) {
  switch (location) {
    case views.CHECKOUT:
      return DefaultConfirmationExtractor.extractFromQuery(
        document,
        confirmationExtractorConfig.getSelector(views.CHECKOUT),
      );
    case views.ACCOUNT_ORDER:
      return DefaultConfirmationExtractor.extractFromQuery(
        document,
        confirmationExtractorConfig.getSelector(views.ACCOUNT_ORDER),
      );
    default:
      return null;
  }
}
