import { MountConfigType } from '../mount-config/config-types';

export const defaultClassesConfig: MountConfigType = {
  account_order: '',
  order_confirmation: '',
  public_order: '',
};

export const defaultStylesConfig: MountConfigType = {
  public_order: '',
  order_confirmation: '',
  account_order: '',
};

export const defaultMountSelectorConfig: MountConfigType = {
  public_order: '.content-box',
  order_confirmation: '.content-box',
  account_order: '.order-table',
};

export const defaultOrderExtractorConfig: MountConfigType = {
  public_order: '.os-order-number',
  order_confirmation: '.os-order-number',
  account_order: 'div.grid.myaccount > div.grid__item.myaccount__order-history > h2',
};

export const defaultConfirmationExtractorConfig: MountConfigType = {
  public_order: '',
  order_confirmation: '',
  account_order: '',
};
