import React from 'react';
import ContainerVertical from '../elements/container-vertical';
import Label from '../elements/label';
import Textarea from '../elements/textarea';
import HSpacer from '../elements/spacer-horizontal';
import Text from '../elements/text';

interface ITextArea {
  id: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  label: string;
  placeholder?: string;
  error?: boolean;
  maxLength?: number;
  rows?: number;
}

export default function TextArea(props: ITextArea) {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;

    if (props.maxLength) {
      if (text.length <= props.maxLength) {
        props.onChange(text);
      }
    } else {
      props.onChange(text);
    }
  };

  return (
    <ContainerVertical align="flex-start" custom input>
      <Label htmlFor={props.name}>
        <Text weight="bold" allCaps>
          {props.label}
        </Text>
      </Label>
      <HSpacer space="5px" />
      <Textarea
        id={props.id}
        placeholder={props.placeholder}
        name={props.name}
        rows={props.rows ?? 2}
        value={props.value}
        onChange={handleChange}
        error={props.error}
      />
    </ContainerVertical>
  );
}
