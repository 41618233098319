import styled from 'styled-components';
import colors from '../resources/colors';
import sizes from '../resources/sizes';

const LinkButton = styled.button`
  border: none;
  border-radius: 0;
  background: none;
  color: ${colors.royal};
  text-decoration-line: underline;
  cursor: pointer;
  font-size: ${sizes.descriptionFontSize};
  line-height: ${sizes.descriptionLineHeight};
`;

export default LinkButton;
