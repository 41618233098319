import React from 'react';
import { IChannel } from '../../interfaces/channel';
// import FacebookMessengerOption from './facebook-messenger';
import SMSOption from './sms';
import Container from '../../elements/container';

import * as channel from '../../config/constants/channel';
import ResponsiveHSpacer from '../../elements/responsive-horizontal-spacer';
import WhatsappOption from './whatsapp';

function selectChannel(channelId: string, onClick: () => void): React.ReactElement {
  switch (channelId) {
    // case channel.MESSENGER:
    //   return <FacebookMessengerOption onClick={onClick} />;
    case channel.SMS:
      return <SMSOption onClick={onClick} />;
    case channel.WHATSAPP:
      return <WhatsappOption onClick={onClick} />;
    default:
      return <></>;
  }
}

interface IChannelSelectorList {
  channels: IChannel[];
  onSelect: (channel: IChannel) => void;
}

export default function ChannelSelectorList(props: IChannelSelectorList) {
  const handleSelect = (id: string) => {
    const selectedChannel = props.channels.find((item) => item.id === id) as IChannel;
    props.onSelect(selectedChannel);
  };

  return (
    <Container fullWidth>
      <ResponsiveHSpacer mobileSpace="20px" defaultSpace="0" />
      {props.channels
        .filter((ch) => ch.enabled !== false)
        .map((item) => selectChannel(item.id, () => handleSelect(item.id)))}
    </Container>
  );
}
