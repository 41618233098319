import styled from 'styled-components';
import colors from '../resources/colors';

interface Props {
  error?: boolean;
}
const Input = styled.input`
  box-sizing: border-box;

  &[type='text'] {
    border-radius: 4px;
    padding: 8px 16px;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    background-color: ${colors.inputBackground};
    color: ${(props: Props) => (props.error ? colors.red : colors.inputColor)};
    border: 2px solid ${colors.superLightGray};

    &:focus {
      border: 2px solid ${colors.descriptionForeground};
    }
  }

  &[type='radio'] {
    opacity: 0;
    width: 0;
    height: 0;
    cursor: pointer;
  }
`;

export default Input;
