export class DefaultConfirmationExtractor {
  static extractFromQuery(doc: Document, selector: string): string | undefined {
    if (!selector) return undefined;

    const elem = doc.querySelector(selector);
    if (!elem) return undefined;

    return elem.innerHTML;
  }
}
