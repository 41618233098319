import * as Sentry from '@sentry/browser';
import { MountStrategy } from '../mount-strategy';
import * as magentoUtils from '../utils/magentov1-utils';
import { TRAKR_WIDGET_ELEMENT_ID } from '../mounter-helpers';

const magentoV1MountStrategy: MountStrategy = (onMount, onUnmount) =>
  function magentoV1Handler() {
    try {
      const url = window.location.href;
      const location = magentoUtils.determineLocation(url);
      const target = location ? magentoUtils.getTarget(location) : undefined;

      if (location && target && !document.querySelector(`#${TRAKR_WIDGET_ELEMENT_ID}`)) {
        const entrypoint = magentoUtils.createEntrypoint(location);
        if (entrypoint) {
          target?.parentNode?.insertBefore(entrypoint, target.nextSibling);
          const order = magentoUtils.extractOrder(location);
          const confirmation = magentoUtils.extractConfirmation(location);

          if (typeof order === 'string') {
            onMount(entrypoint, order, confirmation ?? undefined);
          }
        }
      }

      if (!location) {
        onUnmount();
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

export default magentoV1MountStrategy;
