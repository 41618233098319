import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children: any;
  custom?: boolean;
  input?: boolean;
  minHeight?: string;
}

interface IContainerVertical {
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  align?: 'center' | 'flex-start' | 'flex-end';
  spaceBetweenChild?: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  padding?: string;
  minHeight?: string;
  maxWidth?: string;
}

const vContainer = (props: Props) => (
  <div
    className={`${props.className} ${
      props.custom ? `trakr__v-container${props.input ? '__input' : ''}` : ''
    }`}
  >
    {props.children}
  </div>
);

const ContainerVertical = styled(vContainer)`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${(props: IContainerVertical) => (props.justify ? props.justify : 'center')};
  align-items: ${(props: IContainerVertical) => (props.align ? props.align : 'center')};
  height: ${(props: IContainerVertical) => (props.fullHeight ? '100%' : 'inherit')};
  min-height: ${(props: IContainerVertical) => (props.minHeight ? props.minHeight : 'unset')};
  width: ${(props: IContainerVertical) => (props.fullWidth ? '100%' : 'inherit')};
  padding: ${(props: IContainerVertical) => (props.padding ? props.padding : '0')};
  max-width: ${(props: IContainerVertical) => (props.maxWidth || 'none')};
`;

export default ContainerVertical;
