import React from 'react';
import colors from '../colors';

interface IMessenger {
  background?: string;
}

const Messenger = (props: IMessenger) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* eslint-disable-next-line max-len */}
    <path d="M9.8994 0C0.609217 0 -3.56043 10.9821 3.67618 16.4554V20L7.05565 18.125C13.4039 19.9018 19.7967 15.4509 19.7967 9.25447C19.8012 4.14286 15.3681 0 9.8994 0ZM10.944 12.4152L8.38154 9.75446L3.45297 12.4821L8.85922 6.74554L11.4217 9.40625L16.3503 6.67857L10.944 12.4152Z" fill={props.background ? props.background : colors.facebook} />
  </svg>
);

export default Messenger;
