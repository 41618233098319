export default class TrayCommerceOrderExtractor {
  static extractFromPublicOrder(doc: Document, selector: string): string | undefined {
    const elem = doc.querySelector(selector);
    if (!elem) return undefined;

    return this.extractUsingRegex(elem.innerHTML, /([\d]+)/g);
  }

  static extractFromAccountOrder(doc: Document, selector: string): string | undefined {
    const elem = doc.querySelector(selector);
    if (!elem) return undefined;

    return this.extractUsingRegex(elem.innerHTML, /([\d]+)/g);
  }

  static extractFromCheckout(doc: Document, selector: string): string | undefined {
    const elem = doc.querySelector(selector);
    if (!elem) return undefined;

    return this.extractUsingRegex(elem.innerHTML, /([\d]+)/g);
  }

  static extractUsingRegex(text: string, rx: RegExp) {
    const regexResult = rx.exec(text);
    return regexResult && regexResult.length > 1 ? regexResult[1] : undefined;
  }
}
