import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ContainerVertical from '../../elements/container-vertical';
import LinkButton from '../../elements/link-button';
import { StateModel } from '../../store/state-model';
import { selectChannel, confirmChannelSelection } from '../../store/actions/channel';
import { IReduxAction } from '../../interfaces/redux-action';
import MessengerConfirmation from './facebook-messenger-confirmation';
import WhatsappConfirmation from './whatsapp-confirmation';
import SMSConfirmation from './sms-confirmation';
import ContainerHorizontal from '../../elements/container-horizontal';
import { IChannel } from '../../interfaces/channel';

import ChannelSelectorList from './channel-selector-list';
import * as channel from '../../config/constants/channel';

interface IChannelSelector {
  channels: IChannel[];
  selected?: IChannel;
  onSelect: (channel: IChannel) => void;
  onNotBeNotified: () => void;
}

/**
 * Channel chooser
 *
 * @param props
 * @constructor
 */
export function ChannelSelector(props: IChannelSelector) {
  const { t } = useTranslation();

  if (props.selected) {
    switch (props.selected.id) {
      case channel.MESSENGER:
        return <MessengerConfirmation />;
      case channel.SMS:
        return <SMSConfirmation />;
      case channel.WHATSAPP:
        return <WhatsappConfirmation />;
      default:
        return <></>;
    }
  }

  return (
    <ContainerHorizontal>
      <ContainerVertical justify="center" align="flex-start" minHeight="160px" fullWidth>
        <ChannelSelectorList channels={props.channels} onSelect={props.onSelect} />
        <ContainerHorizontal>
          <LinkButton type="button" onClick={props.onNotBeNotified}>
            {t('NOT_BE_NOTIFIED')}
          </LinkButton>
        </ContainerHorizontal>
      </ContainerVertical>
    </ContainerHorizontal>
  );
}

const mapState = (state: StateModel) => ({
  selected: state.channel.selected,
  channels: state.channel.options,
});

const mapDispatch = (dispatch: (action: IReduxAction) => unknown) => ({
  onSelect: (selectedChannel: IChannel) => dispatch(selectChannel(selectedChannel)),
  onNotBeNotified: () =>
    dispatch(confirmChannelSelection({ channel: channel.NOT_DISTURB, data: {} })),
});

export default connect(mapState, mapDispatch)(ChannelSelector);
