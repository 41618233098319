import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button';
import { IActionButton } from './shared';
import colors from '../../resources/colors';
import * as AppModes from '../../config/constants/mode';
import { StateModel } from '../../store/state-model';
import { setApplicationMode } from '../../store/actions/app';
import * as TrackingActions from '../../config/constants/tracking-actions';

export function ActionChangeChannel(props: IActionButton) {
  const { t } = useTranslation();

  return (
    <Button
      id={TrackingActions.CHANGE_CHANNEL}
      disabled={props.loading}
      background={colors.green}
      color={colors.buttonForeground}
      onClick={props.onAction}
      margin="0 8px 0 0"
    >
      {t('ACTION_CHANGE_CHANNEL')}
    </Button>
  );
}

const mapState = (state: StateModel) => ({
  loading: state.app.loading,
});

const mapDispatch = (dispatch: Function) => ({
  onAction: () => dispatch(setApplicationMode(AppModes.CHOOSE_CHANNEL)),
});

export default connect(mapState, mapDispatch)(ActionChangeChannel);
