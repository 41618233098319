import styled from 'styled-components';
import sizes from '../resources/sizes';

interface IHSpacer {
  space?: string;
}

const HSpacer = styled.div`
  box-sizing: border-box;
  height: ${(props: IHSpacer) => (props.space ? props.space : sizes.defaultHorizontalSpace)};
  width: 100%;
`;

export default HSpacer;
