import { useDispatch, useSelector } from 'react-redux';
import { setApplicationMode } from '../store/actions/app';
import { AppMode, StateModel } from '../store/state-model';

export function useAppMode() {
  const mode = useSelector((state: StateModel) => state.app.mode);
  const dispatch = useDispatch();

  const setAppMode = (mode: AppMode) => dispatch(setApplicationMode(mode));

  return [mode, setAppMode];
}
