import { StateModel } from './state-model';

import * as mode from '../config/constants/mode';

const state: StateModel = {
  app: {
    ready: false,
    server: '',
    loading: false,
    mode: mode.NOT_READY,
    order: undefined,
    email: undefined,
  },
  order: {
    status: '',
  },
  channel: {
    hasChannel: false,
    selected: undefined,
    selectedConfirmed: false,
    options: [],
    storedChannels: [],
    bypassCheck: false,
  },
  tracking: {
    trackingExpanded: false,
    actions: [],
    trackingCode: undefined,
    estimatedDelivery: undefined,
    events: [],
  },
  ecommerce: {
    actions: [],
    processes: [],
  },
  customer: {
    email: '',
  },
};

export const createInitialState = (injectedState = {}): StateModel => ({
  ...state,
  ...injectedState,
});

export default state;
