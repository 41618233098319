import styled from 'styled-components';
import sizes from '../resources/sizes';

type Props = {
  mobileSpace: string;
  defaultSpace: string;
};

const ResponsiveHSpacer = styled.div`
  box-sizing: border-box;
  height: ${(props: Props) => props.defaultSpace};
  width: 100%;

  @media screen and (max-width: ${sizes.maxWidthMobile}) {
    height: ${(props: Props) => props.mobileSpace};
  }
`;

export default ResponsiveHSpacer;
