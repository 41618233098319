import { select } from 'redux-saga/effects';
import TrakrError from '../../errors/trakr-error';

function* bubbleErrors(action: { type: string, payload: string }) {
  const dump = yield select((state) => state);

  throw new TrakrError(action.payload, dump);
}

export default bubbleErrors;
