import React from 'react';
import colors from '../colors';

interface IWhatsApp {
  background?: string
}

const WhatsApp = (props: IWhatsApp) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* eslint-disable-next-line max-len */}
    <path d="M9.94721 0C4.46455 0 0.00754148 4.45701 0 9.93967C0 11.8175 0.527904 13.6501 1.52338 15.2262L1.75716 15.6033L0.754148 19.2685L4.51735 18.2805L4.87934 18.4992C6.40272 19.4042 8.15234 19.8869 9.93967 19.8869H9.94721C15.4223 19.8869 20 15.4299 20 9.94721C20 7.29261 18.8537 4.79638 16.9759 2.91855C15.0905 1.03318 12.6018 0 9.94721 0ZM15.7919 14.2081C15.543 14.9095 14.3514 15.543 13.7783 15.6259C12.8281 15.7692 12.089 15.6938 10.1961 14.8793C7.20211 13.5822 5.24133 10.5656 5.0905 10.3695C4.93967 10.1735 3.86878 8.74812 3.86878 7.27753C3.86878 5.80694 4.63801 5.08296 4.91704 4.7813C5.18854 4.47964 5.51282 4.40422 5.71644 4.40422C5.91252 4.40422 6.11614 4.40422 6.28959 4.41177C6.47059 4.41931 6.71946 4.34389 6.96078 4.92459C7.20965 5.52036 7.80543 6.99095 7.88085 7.14178C7.95626 7.29261 8.00905 7.46606 7.90347 7.66214C7.33032 8.80845 6.71946 8.7632 7.02866 9.2911C8.1825 11.2745 9.33635 11.9608 11.0935 12.8431C11.3952 12.994 11.5686 12.9713 11.7421 12.7677C11.9155 12.5716 12.4887 11.8929 12.6848 11.5988C12.8808 11.2971 13.0845 11.3499 13.356 11.448C13.6275 11.546 15.098 12.27 15.3997 12.4208C15.7014 12.5716 15.8974 12.6471 15.9729 12.7677C16.0407 12.911 16.0407 13.5143 15.7919 14.2081Z" fill={props.background ? props.background : colors.whatsapp} />
  </svg>
);

export default WhatsApp;
