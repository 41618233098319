import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children: any;
  custom?: boolean;
}

interface IContainer {
  height?: string;
  fullWidth?: boolean;
}

const container = (props: Props) => (
  <div className={`${props.className} ${props.custom ? 'trakr__container' : ''}`}>
    {props.children}
  </div>
);

const Container = styled(container)`
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: ${(props: IContainer) => (props.fullWidth ? '100%' : 'inherit')};
  height: ${(props: IContainer) => (props.height ? props.height : 'inherit')};
`;

export default Container;
