import { IReduxAction } from '../../interfaces/redux-action';

export interface OrderState {
  status: string;
  trackingUrl?: string;
}

const initialState: OrderState = {
  status: '',
};

export default function order(state = initialState, action: IReduxAction) {
  switch (action.type) {
    case 'SET_ORDER_STATUS':
      return { ...state, status: action.payload };
    case 'RESET_ORDER_STATE':
      return initialState;
    case 'SET_ORDER_TRACKING_URL':
      return { ...state, trackingUrl: action.payload };
    default:
      return state;
  }
}
