import React from 'react';
import { useTranslation } from 'react-i18next';
import SMSIcon from '../../resources/icons/sms';
import colors from '../../resources/colors';
import ChannelOptionButton from '../../elements/channel-option-button';

type Props = {
  onClick(): void;
};

export default function SMSOption(props: Props) {
  const { t } = useTranslation();

  return (
    <ChannelOptionButton
      label={t('TEXT_MESSAGE')}
      Icon={<SMSIcon background={colors.textForeground} />}
      accent={colors.appBackground}
      onClick={props.onClick}
    />
  );
}
