import { MountStrategy } from '../mount-strategy';
import { TRAKR_WIDGET_ELEMENT_ID } from '../mounter-helpers';
import * as vtexUtils from '../utils/vtex-utils';

const vtexMountStrategy: MountStrategy = (onMount, onUnmount) =>
  function vtexHandler() {
    const url = window.location.href;
    const location = vtexUtils.determineLocation(url);
    const target = location ? vtexUtils.getTarget(location) : undefined;

    if (location && target && !document.querySelector(`#${TRAKR_WIDGET_ELEMENT_ID}`)) {
      const entryPoint = vtexUtils.createEntrypoint(location);
      if (entryPoint) {
        target?.parentNode?.insertBefore(entryPoint, target.nextSibling);
        const order = vtexUtils.extractOrder(location);
        const confirmation = vtexUtils.extractConfirmation(location);

        if (typeof order === 'string') {
          onMount(entryPoint, order, confirmation ?? undefined);
        }
      }
    }

    if (!location) {
      onUnmount();
    }
  };

export default vtexMountStrategy;
