import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import colors from '../../resources/colors';
import { confirmChannelSelection, selectChannel } from '../../store/actions/channel';
import ContainerVertical from '../../elements/container-vertical';
import ContainerHorizontal from '../../elements/container-horizontal';
import Button from '../../elements/button';
import { IReduxAction } from '../../interfaces/redux-action';
import SMSIcon from '../../resources/icons/sms';
import InputText from '../input-text';
import HSpacer from '../../elements/spacer-horizontal';
import VSpacer from '../../elements/spacer-vertical';
import { StateModel } from '../../store/state-model';
import PolicyTerms from '../policy-terms';

import * as channel from '../../config/constants/channel';
import ResponsiveHSpacer from '../../elements/responsive-horizontal-spacer';
import ButtonTransparent from '../../elements/button-transparent';
import LeftIcon from '../../resources/icons/left';
import PhoneMask from '../../masks/phone';

interface ISMSConfirmation {
  phone?: string;
  loading?: boolean;
  onConfirm: (phone: string) => void;
  selectOther: () => void;
}

export function SMSConfirmation(props: ISMSConfirmation) {
  const [phone, setPhone] = useState(props.phone ?? '');
  const error = !PhoneMask.validate(phone);
  const { t } = useTranslation();

  const handlePhoneChange = (newPhone: string) => {
    setPhone(newPhone);
  };

  const handleConfirm = () => {
    if (phone && PhoneMask.validate(phone)) {
      props.onConfirm(`55${phone}`);
    }
  };

  return (
    <ContainerVertical fullWidth justify="flex-start">
      <ResponsiveHSpacer mobileSpace="20px" defaultSpace="0" />
      <ContainerHorizontal justify="flex-start" align="flex-start" fullWidth>
        <ButtonTransparent onClick={props.selectOther} id="trakr-sms-confirmation-back-btn">
          <ContainerHorizontal>
            <LeftIcon />
            {t('BACK')}
          </ContainerHorizontal>
        </ButtonTransparent>
      </ContainerHorizontal>

      <HSpacer space="20px" />
      <ContainerVertical fullWidth>
        <InputText
          id="sms-input"
          name="sms-input"
          label={t('CELL_PHONE_WITH_DDD')}
          placeholder={t('CELL_PHONE_WITH_DDD_PLACEHOLDER')}
          value={phone}
          onChange={handlePhoneChange}
          maxLength={11}
          format={PhoneMask.maskWithoutCountry}
          unformat={PhoneMask.unmask}
          error={error}
        />

        <HSpacer space="15px" />

        <Button
          id="trakr-sms-confirm-btn"
          background={colors.green}
          onClick={handleConfirm}
          disabled={props.loading || error}
        >
          <ContainerHorizontal spaceBetweenChild="10px">
            <SMSIcon background={colors.buttonForeground} />
            <VSpacer space="10px" />
            {t('CONFIRM')}
          </ContainerHorizontal>
        </Button>

        <HSpacer space="15px" />

        <PolicyTerms />
      </ContainerVertical>
    </ContainerVertical>
  );
}

const mapState = (state: StateModel) => ({
  phone: state.customer.phone?.slice(2),
  loading: state.app.loading,
});

const mapDispatch = (dispatch: (action: IReduxAction) => unknown) => ({
  onConfirm: (phone: string) =>
    dispatch(confirmChannelSelection({ channel: channel.SMS, data: { phone } })),
  selectOther: () => dispatch(selectChannel()),
});

export default connect(mapState, mapDispatch)(SMSConfirmation);
