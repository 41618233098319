import styled from 'styled-components';
import colors from '../resources/colors';

const Label = styled.label`
  cursor: pointer;
  color: ${colors.textForeground};

  &[disabled] {
    cursor: not-allowed;
  }
`;

export default Label;
