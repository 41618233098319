import React from 'react';
import colors from '../colors';

interface Props {
  background?: string;
}

const LeftIcon = (props: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8286 19C13.5366 19 13.2466 18.873 13.0486 18.627L8.2206 12.627C7.9226 12.256 7.9266 11.726 8.2316 11.36L13.2316 5.36C13.5846 4.936 14.2156 4.879 14.6406 5.232C15.0646 5.585 15.1216 6.216 14.7676 6.64L10.2926 12.011L14.6076 17.373C14.9536 17.803 14.8856 18.433 14.4546 18.779C14.2706 18.928 14.0486 19 13.8286 19Z"
      fill={props.background ?? colors.textForeground}
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="5" width="7" height="14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8286 19C13.5366 19 13.2466 18.873 13.0486 18.627L8.2206 12.627C7.9226 12.256 7.9266 11.726 8.2316 11.36L13.2316 5.36C13.5846 4.936 14.2156 4.879 14.6406 5.232C15.0646 5.585 15.1216 6.216 14.7676 6.64L10.2926 12.011L14.6076 17.373C14.9536 17.803 14.8856 18.433 14.4546 18.779C14.2706 18.928 14.0486 19 13.8286 19Z"
        fill="transparent"
      />
    </mask>
  </svg>
);

export default LeftIcon;
