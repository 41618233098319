import React from 'react';
import { useTranslation } from 'react-i18next';

import ContainerVertical from '../../elements/container-vertical';
import StepperIconContainer from '../../elements/stepper-icon-container';
import Title from '../../elements/title';
import StepperSection from '../../elements/stepper-section';
import colors from '../../resources/colors';
import * as mode from '../../config/constants/mode';
import ButtonLink from '../../elements/button-link';
import { useTimelineExpanded } from '../../hooks/use-timeline-expanded';
import { useTimelineLength } from '../../hooks/use-timeline-length';
import { useAppMode } from '../../hooks/use-app-mode';

export default function ShowMoreEvents() {
  const [expanded, setExpanded] = useTimelineExpanded();
  const [appMode] = useAppMode();
  const numberEvents = useTimelineLength();
  const { t } = useTranslation();

  if (numberEvents <= 2 || appMode !== mode.TRACKING) {
    return <></>;
  }

  return (
    <StepperSection>
      <ContainerVertical align="center" justify="flex-start" padding="9px 0 0 0">
        <StepperIconContainer color={expanded ? colors.lightGray : colors.green} />
      </ContainerVertical>

      <ContainerVertical align="flex-start" padding="0 0 0 20px">
        <ButtonLink
          data-testid="expand-timeline-button"
          type="button"
          onClick={() => setExpanded(!expanded)}
        >
          <Title>
            {expanded ? t('SHOW_LESS') : t('SHOW_MORE', { events_length: numberEvents - 2 })}
          </Title>
        </ButtonLink>
      </ContainerVertical>
    </StepperSection>
  );
}
