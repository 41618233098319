import styled from 'styled-components';
import sizes from '../resources/sizes';

interface IVSpacer {
  space?: string;
  mspace?: string; // space when mobile
}

const VSpacer = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: ${(props: IVSpacer) => (props.space ? props.space : sizes.defaultHorizontalSpace)};
`;

export default VSpacer;
