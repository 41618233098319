import EntrypointConfig from '../mount-config/entrypoint-config';
import MountSelectorConfig from '../mount-config/mount-selector-config';
import OrderExtractorConfig from '../mount-config/order-extractor-config';
import {
  defaultClassesConfig,
  defaultConfirmationExtractorConfig,
  defaultMountSelectorConfig,
  defaultOrderExtractorConfig,
  defaultStylesConfig,
} from '../defaults/magentov1-defaults';
import { MountLocation } from '../mount-config/config-types';
import { TRAKR_WIDGET_ELEMENT_ID } from '../mounter-helpers';
import * as views from '../../config/view';
import MagentoV1OrderExtractor from '../order-extractor/magentov1-order-extractor';
import { DefaultConfirmationExtractor } from '../confirmation-extractor/default-confirmation-extractor';
import ConfirmationExtractorConfig from '../mount-config/confirmation-extractor-config';

export const mountLocations = {
  accountOrder: /sales\/order\/view\/order_id\/([0-9]+)/gi,
  checkout: /checkout\/onepage\/success/gi,
};

const entrypointConfig = new EntrypointConfig(defaultClassesConfig, defaultStylesConfig);
const mountSelectorConfig = new MountSelectorConfig(defaultMountSelectorConfig);
const orderExtractorConfig = new OrderExtractorConfig(defaultOrderExtractorConfig);
const confirmationExtractorConfig = new ConfirmationExtractorConfig(
  defaultConfirmationExtractorConfig,
);

export function createEntrypoint(location: MountLocation) {
  const entrypoint = document.createElement('div');
  entrypoint.setAttribute('id', TRAKR_WIDGET_ELEMENT_ID);

  // @ts-ignore
  entrypoint.style = entrypointConfig.getStyles(location) ?? '';
  const customClasses = entrypointConfig.getClasses(location);
  if (customClasses) {
    entrypoint.classList.add(customClasses);
  }
  return entrypoint;
}

export function determineLocation(url: string): MountLocation | undefined {
  // reset the regex pointers
  mountLocations.accountOrder.lastIndex = 0;
  mountLocations.checkout.lastIndex = 0;

  const accountOrder = mountLocations.accountOrder.test(url) ? views.ACCOUNT_ORDER : undefined;
  const checkout = mountLocations.checkout.test(url) ? views.CHECKOUT : undefined;

  return accountOrder ?? checkout ?? undefined;
}

export function getTarget(location: MountLocation) {
  return document.querySelector(mountSelectorConfig.getSelector(location));
}

export function extractOrder(location: MountLocation) {
  switch (location) {
    case views.CHECKOUT:
      return MagentoV1OrderExtractor.extractFromCheckout(
        document,
        orderExtractorConfig.getSelector(views.CHECKOUT),
      );
    case views.ACCOUNT_ORDER:
      return MagentoV1OrderExtractor.extractFromAccountOrder(
        document,
        orderExtractorConfig.getSelector(views.ACCOUNT_ORDER),
      );
    default:
      return null;
  }
}

export function extractConfirmation(location: MountLocation) {
  switch (location) {
    case views.CHECKOUT:
      return DefaultConfirmationExtractor.extractFromQuery(
        document,
        confirmationExtractorConfig.getSelector(views.CHECKOUT),
      );
    case views.ACCOUNT_ORDER:
      return DefaultConfirmationExtractor.extractFromQuery(
        document,
        confirmationExtractorConfig.getSelector(views.ACCOUNT_ORDER),
      );
    default:
      return null;
  }
}
